import StarImg from "@assets/wordpuzzle/star-kuiz.svg";

import QuizButton from "./QuizButton";

type ResultsSectionProps = {
  handleQuizNavigation: () => void;
};

export default function ResultsSection({
  handleQuizNavigation,
}: ResultsSectionProps) {
  return (
    <div className="flex w-full py-lg mb-xl lg:bg-gradient-to-r from-white-700 to-white-300">
      <div className="flex w-full items-center justify-center gap-[24px] flex-col">
        <div>
          <img src={StarImg} alt="star-kuiz" width={260} height={90} />
        </div>
        <div className="flex flex-col gap-xs font-semibold items-center text-grey-800">
          <span className="text-[30px] lg:text-xxxl">Hebat!</span>
          <span className="text-md lg:text-[20px]">
            Percubaan yang cemerlang
          </span>
        </div>
        <QuizButton handleClick={handleQuizNavigation} />
      </div>
    </div>
  );
}
