import { capitalizeFirstCharOnly } from "@src/app/helper/stringTransform";
import { twMerge } from "tailwind-merge";

type QuestionCardProps = {
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctMalayWord: string;
  onWordClick: (word: string) => void;
  isMobile?: boolean;
};

function QuestionCard({
  englishWord,
  options,
  selectedWord,
  correctMalayWord,
  onWordClick,
  isMobile,
}: QuestionCardProps): JSX.Element {
  return (
    <div
      className={
        isMobile
          ? "flex flex-col items-center mt-lg mb-xl gap-[25px] px-sm"
          : "flex flex-col items-center my-lg gap-[25px] min-w-[335px]"
      }
    >
      <div className="mb-0">
        <span
          id="staticEnglish_web"
          className="font-secondary font-semibold text-blue-400 text-2md"
        >
          {capitalizeFirstCharOnly(englishWord)}
        </span>
      </div>
      <div className={`flex w-full flex-col ${isMobile && "items-center"}`}>
        <ul
          className={`inline-flex flex-col items-center gap-[25px] p-0 list-none ${isMobile && "w-full max-w-[375px]"}`}
        >
          {options.map((word, index) => (
            <div
              key={index}
              onClick={() => {
                onWordClick(word);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onWordClick(word);
                }
              }}
              role="button"
              tabIndex={0}
              className={twMerge(
                "font-primary flex justify-center items-center px-xxs w-full h-[48px] bg-white-300 lg:bg-white-100 cursor-pointer text-grey-800 text-md rounded-[30px]",
                selectedWord !== null
                  ? word === selectedWord
                    ? word.toLowerCase() === correctMalayWord
                      ? "bg-green-300 lg:bg-green-300"
                      : "bg-red-700 lg:bg-red-700"
                    : ""
                  : "",
              )}
            >
              {capitalizeFirstCharOnly(word)}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QuestionCard;
