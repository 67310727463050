import { ProcessedSubHead } from "@app/types/Opensearch";
import ApplyParagraphAnnotations from "@elements/ArticleParagraph/ApplyParagraphAnnotations";
import Heading from "@elements/Typography/Heading";

type SubHeadProps = {
  element: ProcessedSubHead;
};

export default function SubHead({ element }: SubHeadProps) {
  return (
    <Heading
      className="font-medium text-xl text-black-300"
      Element={element.headingTag}
    >
      {ApplyParagraphAnnotations(element.subhead, element.annotations)}
    </Heading>
  );
}
