import { SHOW_NEW_HOME_PAGE } from "@helper/getEnvVariables";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import BHBasePage, { type BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useState } from "react";

import { PayloadResponseType } from "./Home.server";
import HomeMetaData from "./HomeMetaData";
import HomePageNew from "./HomePageLayout/HomePageNew";
import HomePageOld from "./HomePageOld";

export function HomePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  const renderedArticleIds = useMemo(() => new Set<string>(), []);
  const [excludeIds, setExcludeIds] = useState<string>("");

  const updateRenderedArticleIds = useCallback(
    (articles: ProcessedArticleData[]) => {
      articles.forEach((article) => {
        renderedArticleIds.add(article.id);
      });
      setExcludeIds([...renderedArticleIds].join(","));
    },
    [renderedArticleIds],
  );

  useEffect(() => {
    if (dataLoaderResponse.type === ResponseType.SUCCESS) {
      const {
        storyQueue,
        latestRegisteredArticle,
        latestFreeArticle,
        latestArticles,
      } = dataLoaderResponse.payload;

      if (parseInt(SHOW_NEW_HOME_PAGE)) {
        updateRenderedArticleIds([...storyQueue, ...latestArticles]);
      } else {
        updateRenderedArticleIds([
          ...storyQueue,
          ...latestRegisteredArticle,
          ...latestFreeArticle.slice(0, 8),
        ]);
      }
    }
  }, [dataLoaderResponse, updateRenderedArticleIds]);

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const {
    storyQueue,
    latestRegisteredArticle,
    latestFreeArticle,
    latestArticles,
  } = dataLoaderResponse.payload;
  const topStoryQueue = storyQueue[0];
  const subStoryQueue = [...storyQueue.slice(1)];

  const adsSectionName = "home";
  const { gaData, metaTags } = HomeMetaData(adsSectionName);
  const pageAdTargetValue = PageAdTargetingTypeEnum.HOME;

  const basePageProps: BHBasePageProps = {
    uniqueSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
    hasLb1Ads: true,
  };

  const renderHomePage = () => {
    if (parseInt(SHOW_NEW_HOME_PAGE)) {
      return (
        <HomePageNew
          topStoryQueue={storyQueue.slice(0, 4)}
          latestFreeArticle={latestArticles}
          adsSectionName={adsSectionName}
          pageAdTargetValue={pageAdTargetValue}
          excludeIds={excludeIds}
        />
      );
    }

    return (
      <HomePageOld
        topStoryQueue={topStoryQueue}
        subStoryQueue={subStoryQueue}
        adsSectionName={adsSectionName}
        pageAdTargetValue={pageAdTargetValue}
        latestRegisteredArticle={latestRegisteredArticle}
        latestFreeArticle={latestFreeArticle}
        excludeIds={excludeIds}
      />
    );
  };

  return <BHBasePage {...basePageProps}>{renderHomePage()}</BHBasePage>;
}
