import { SectionTitle } from "@components/Elements/Typography/Title";
import SideBlockAds from "@container/SideBlockAds";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import type { SectionPayloadResponseType } from "./Section.server";
import SectionArticlesList from "./SectionArticlesList";
import { SectionMetaData } from "./SectionMetaData";

export function SectionPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<SectionPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const {
    data: sectionArticles,
    paywall,
    section: sectionName,
  } = dataLoaderResponse.payload;
  const parentSection = sectionArticles[0].parentSection;
  const { gaData, metaTags } = SectionMetaData({
    sectionName,
    parentSection,
    paywall,
  });

  const adsSectionName = parentSection ? parentSection : sectionName;
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const basePageProps: BHBasePageProps = {
    uniqueSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="max-w-screen-cxl m-auto">
        <SectionTitle sectionName={sectionName} />
        <div className="flex flex-col lg:flex-row w-full gap-4">
          <SectionArticlesList
            sectionArticles={sectionArticles}
            sectionName={sectionName}
            adsSectionName={adsSectionName}
            paywall={paywall}
          />
          <SideBlockAds
            uniqueSectionName={adsSectionName}
            className="max-w-[400px] py-xs lg:px-6 sticky top-[130px]"
            pageAdTargetingProps={pageAdTargetValue}
          />
        </div>
      </div>
    </BHBasePage>
  );
}
