import { RestaurantDataWithDistance } from "@app/types/foodMapTypes";

type FoodMapRestaurantCardProps = {
  index: number;
  restaurant: RestaurantDataWithDistance;
  handleRestaurantSelect: (restaurant: RestaurantDataWithDistance) => void;
};

export default function FoodMapRestaurantCard({
  index,
  restaurant,
  handleRestaurantSelect,
}: FoodMapRestaurantCardProps) {
  const onClick = () => {
    handleRestaurantSelect(restaurant);
  };

  return (
    <>
      <div
        key={index}
        className="flex py-2 pr-1 gap-1 items-center restaurant-card"
        data-name={restaurant.name}
        onClickCapture={onClick}
      >
        <div className="pl-1 basis-1/6">
          <svg width="40" height="40">
            <circle cx="20" cy="20" r="20" fill="#01519C" />
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              fill="white"
              fontSize="22px"
              fontFamily="Arial"
              dy=".3em"
            >
              {index + 1}
            </text>
            Sorry, your browser does not support inline SVG.
          </svg>
        </div>
        <div className="shadow-foodmap py-1 px-2 basis-5/6 text-black-300">
          <div className="text-sm">{restaurant.name}</div>
          <div className="text-xxs">{restaurant.vicinity}</div>
          <div className="text-xxs">
            {restaurant.distance < 1
              ? `${(restaurant.distance * 1000).toFixed(1)} M `
              : `${restaurant.distance.toFixed(1)} KM `}
            away
          </div>
        </div>
      </div>
    </>
  );
}
