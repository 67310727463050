import fetchBHApi from "@helper/fetchBHApi";
import { useEffect, useState } from "react";

export function useGrapeshotData(urlPath: string) {
  const [gsChannels, setGsChannels] = useState<string[]>([]);

  const fetchGsChannels = async (urlPath: string): Promise<string[]> => {
    const response = await fetchBHApi<string[]>(
      `get-gs-channels?url=${urlPath}`,
      "GET",
    );

    return response;
  };

  useEffect(() => {
    const callFetch = async () => {
      if (urlPath === "") return;
      const gsData = await fetchGsChannels(urlPath);
      setGsChannels(gsData);
    };

    void callFetch();
  }, [urlPath]);

  return { gsChannels };
}
