/**
 * Check if the current platform is iOS (iPhone, iPad, iPod).
 *
 * This function examines the user agent string of the browser to determine if the
 * user is on an iOS device. User agent strings for iOS devices typically include
 * identifiers like "iPad", "iPhone", or "iPod".
 *
 * Example user agent strings:
 * - iPhone: Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
 * - iPad: Mozilla/5.0 (iPad; CPU OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
 * - iPod: Mozilla/5.0 (iPod touch; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
 *
 * @returns {boolean} True if the platform is iOS, false otherwise.
 */
export default function checkPlatformIsiOS(): boolean {
  const userAgent = navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent);
}
