import podcastIcon from "@assets/header/menu/icon/podcast-blue.svg";
import podcastIconHover from "@assets/header/menu/icon/podcast-hover.svg";
import Audio from "@components/Audio/Audio";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function PodcastSideFrame() {
  const [hover, setIsHovered] = useState(false);
  return (
    <div className="flex flex-col min-w-[350px]">
      <div className="flex items-center justify-between">
        <Link
          to="/podcasts"
          className="flex items-center gap-2 text-blue-300 text-lg leading-[135%] font-primary hover:underline hover:text-blue-700"
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
        >
          <img
            src={hover ? podcastIconHover : podcastIcon}
            alt="podtcast"
            width={20}
          />
          Podcasts
        </Link>
        <Link
          reloadDocument
          to="/podcasts"
          className="text-grey-400 text-sm font-primary hover:underline self-end"
        >
          {`more >`}
        </Link>
      </div>

      <Audio src="https://omny.fm/shows/berita-harian/playlists/notapis/embed?style=cover" />
    </div>
  );
}
