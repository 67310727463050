import appleIcon from "@assets/footer/newIcons/AppStore.svg";
import mailIcon from "@assets/footer/newIcons/Email.svg";
import fbIcon from "@assets/footer/newIcons/Facebook.svg";
import androidIcon from "@assets/footer/newIcons/GooglePlay.svg";
import instaIcon from "@assets/footer/newIcons/Instagram.svg";
import twitterIcon from "@assets/footer/newIcons/X-Twitter.svg";
import youtubeIcon from "@assets/footer/newIcons/YouTube.svg";

export type FooterMenuDetails = Readonly<{
  title?: string;
  url: string;
  images?: string;
  newTab?: boolean;
}>;

export const FOOTER_SOCIAL_MENU: FooterMenuDetails[] = [
  {
    url: "https://www.youtube.com/user/BeritaHarianSG",
    images: youtubeIcon,
  },
  {
    url: "https://www.twitter.com/BeritaHarianSG/",
    images: twitterIcon,
  },
  {
    url: "https://www.instagram.com/BeritaHarianSG/",
    images: instaIcon,
  },
  {
    url: "https://www.facebook.com/BeritaHarianSG/",
    images: fbIcon,
  },
  {
    url: "mailto:bhnews@sph.com.sg",
    images: mailIcon,
  },
];

export const FOOTER_TOP_MENU: FooterMenuDetails[] = [
  {
    title: "Hubungi Kami",
    url: "/hubungi-kami",
  },
  {
    title: "Kenali Kami",
    url: "/kenali-kami",
  },
  {
    title: "Syarat dan Peraturan",
    url: "https://www.sph.com.sg/legal/website_tnc/",
  },
  {
    title: "Kenyataan Privasi",
    url: "https://www.sph.com.sg/legal/sph_privacy/",
  },
  {
    title: "Dasar Perlindungan Data",
    url: "https://www.sph.com.sg/legal/pdpa/",
  },
  {
    title: "BH Services",
    url: "/bh-services",
  },
];

export const FOOTER_SUB_MENU: FooterMenuDetails[] = [
  {
    title: "Langgan",
    url: "https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-footer",
  },
  {
    title: "Iklan Bersama Kami",
    url: "https://www.sph.com.sg/advertising-solutions/",
  },
];

export const FOOTER_APP_DOWNLOAD_MENU: FooterMenuDetails[] = [
  {
    url: "https://itunes.apple.com/sg/app/id743558902",
    images: appleIcon,
  },
  {
    url: "https://play.google.com/store/apps/details?id=com.sph.bhandroid",
    images: androidIcon,
  },
];
