import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { SectionDetails } from "@header/headerMenuConstants";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { HeaderMenuType } from "./headerMenuTypes";

type HeaderMenuListProps = {
  testId: string;
  items: SectionDetails[];
  type: HeaderMenuType;
};

export function HeaderMenuList({ testId, items, type }: HeaderMenuListProps) {
  const { pathname } = useLocation();

  const isActive = (location: string): boolean => {
    const pathnameOnly = location.split("?")[0];
    const sectionName = pathname.split("/")[1];
    return pathname === pathnameOnly || `/${sectionName}` === pathnameOnly;
  };

  return (
    <List
      testId={`${testId}-${type}-menuList`}
      style={cx(
        type === "topics" &&
          "flex w-fit h-full items-center justify-center max-h-[39px]",
        type === "secTopics" &&
          "flex fit-content h-full items-center justify-center py-1 whitespace-nowrap",
        type === "misc" && "flex items-center justify-center",
        type === "mobileSection" && "pl-md",
        type === "mobileMisc" && "pl-[48px]",
      )}
    >
      {items.map((item: SectionDetails, index: number) => (
        <ListItem
          key={`${testId}-item-${index}`}
          testId={`${testId}-item-${index}`}
          className={cx(
            "cursor-pointer",
            type === "topics" &&
              `${
                isActive(item.url)
                  ? `bg-${item.selectedColor}`
                  : "bg-transparent"
              } flex items-center justify-center max-h-full  whitespace-nowrap text-sm font-regular gap-xxs px-[16px] pt-xs pb-[8px]`,
            type === "secTopics" &&
              "py-3 px-2 text-sm font-regular text-grey-800",
            type === "misc" && "py-xs text-xxs text-grey-600 ",
            type === "mobileMain" &&
              "flex pr-4 [&_a]:text-grey-800 [&_a]:text-md [&_a]:font-semibold [&_a]:w-full [&_a]:py-3 [&_a]:pl-2 [&_a]:pr-6 [&_img]:w-[20px]",
            type === "mobileMain" && index === 0 && "pl-6",
            type === "mobileMain" && index !== 0 && "pl-12",
            type === "mobileSection" &&
              "flex h-[42px] items-center [&_img]:w-[20px] [&_a]:w-full [&_a]:text-md [&_a]:font-semibold [&_a]:py-3 [&_a]:pl-4 [&_a]:pr-[10px]",
            type === "mobileMisc" &&
              "flex [&_a]:text-grey-800 [&_a]:text-md [&_a]:font-semibold [&_a]:w-full [&_a]:py-3 [&_a]:pl-4 [&_a]:pr-6 ",
          )}
        >
          <>
            {type === "topics" || type === "mobileSection" ? (
              <img
                src={
                  isActive(item.url) && item.selectedIcon
                    ? item.selectedIcon
                    : item.images
                }
                alt={`${item.title}-menu-icon`}
              />
            ) : null}

            <div
              className={`flex items-center gap-xxs min-w-max ${
                isActive(item.url) &&
                type === "secTopics" &&
                `border-b-[3px] border-${item.selectedColor}`
              } ${type === "misc" && "gap-0"}`}
            >
              {item.selectedIcon &&
              type === "secTopics" &&
              isActive(item.url) ? (
                <img
                  src={isActive(item.url) ? item.selectedIcon : item.images}
                  alt={`${item.title}-menu-icon`}
                  className="h-[16px]"
                />
              ) : null}
              <Link
                reloadDocument
                key={index}
                to={item.url}
                className={cx(
                  type === "misc" && "font-regular",
                  type === "topics" && !isActive(item.url)
                    ? item.neutralColor
                      ? "text-grey-800"
                      : "text-red-300"
                    : "",
                  `${
                    !item.neutralColor &&
                    !isActive(item.url) &&
                    "hover:text-grey-800"
                  }`,

                  `${
                    type === "topics" && isActive(item.url) && "text-white-100"
                  }`,
                  type === "secTopics" && isActive(item.url)
                    ? `text-${item.selectedColor}`
                    : "text-grey-800",
                  type === "mobileSection" ? "text-red-300" : "",
                )}
                {...(item.newTab && { target: "_blank" })}
              >
                {item.title}
              </Link>
              {type === "misc" && (
                <span className="px-xxs text-grey-100 text-md font-regular">
                  |
                </span>
              )}
            </div>
          </>
        </ListItem>
      ))}
    </List>
  );
}
