export type LinkIconProps = {
  destination: string;
  src: string;
  alt: string;
  width: string;
  height?: string;
  target?: string;
  className?: string;
};

export default function LinkIcon({
  destination,
  src,
  alt,
  width,
  height,
  target = "_blank",
  className,
}: LinkIconProps) {
  return (
    <a href={destination} target={target}>
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={className}
      />
    </a>
  );
}
