import TranslateArticleCTA from "@assets/article/translateArticleCTA.svg";
import { QuizExpandEvent } from "@components/WordPuzzle/WordPuzzle";
import { isClientSide } from "@src/app/helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { GalleryCarouselTypes } from "@typings/Opensearch.d";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import TranslatedArticleModal from "./TranslatedArticleModal";

export type TranslationCTAProps = {
  article: ProcessedArticleData;
};

export default function TranslationCTA({ article }: TranslationCTAProps) {
  const { id, title, sectionName = "", standFirst, imageGallery } = article;
  const renderItems = imageGallery.filter(
    (item) => item.type === GalleryCarouselTypes.IMAGE,
  );
  const body = MOCK_BODY;
  const isCollapsed = Cookies.get("puzzle_popup_collapsed") === "1";
  const [isQuizExpanded, setIsQuizExpanded] = useState(!isCollapsed);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  useEffect(() => {
    const handleQuizExpandedChange = (event: CustomEvent<QuizExpandEvent>) => {
      setIsQuizExpanded(event.detail.quizExpanded);
    };

    window.addEventListener(
      "quizExpandedChange",
      handleQuizExpandedChange as EventListener,
    );

    return () => {
      window.removeEventListener(
        "quizExpandedChange",
        handleQuizExpandedChange as EventListener,
      );
    };
  }, []);

  useEffect(() => {
    if (!isClientSide) return;

    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [isModalOpen]);

  const handleCTAClick = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      setIsAnimating(true);
    }, 50);
  };

  const handleModalClose = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 300);
  };

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          data-testid={`translated-modal-${id}`}
          className="fixed inset-0 overflow-y-auto w-full h-full bg-white-100 z-[51] lg:pt-[24px]"
        >
          <div
            className={`transform transition-all duration-500 ease-out
          ${
            isAnimating
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          }
        `}
          >
            <TranslatedArticleModal
              id={id}
              title={title}
              sectionName={sectionName}
              standFirst={standFirst}
              body={body}
              imageUrl={renderItems[0].image?.url}
              isOpen={isModalOpen}
              onClose={handleModalClose}
            />
          </div>
        </div>
      )}
      {!isModalOpen && (
        <div
          data-testid={`translate-cta-${id}`}
          className={`fixed h-[34px] transition-all duration-500 ${
            isQuizExpanded ? "bottom-[95px]" : "bottom-[60px]"
          } right-1 lg:right-[32%]`}
        >
          <button onClick={handleCTAClick} aria-label="Translate Article">
            <img
              src={TranslateArticleCTA}
              alt="Translate Article Call to Action"
              width={100}
              height={34}
            />
          </button>
        </div>
      )}
    </div>
  );
}

const MOCK_BODY = [
  "Flexible working arrangements (FWAs) should not be made mandatory as legislating them could lead to disputes and a bitter workplace culture, says a tripartite working group that recently announced guidelines for FWA applications.",
  "In a press conference on April 23, the tripartite working group also emphasized that the FWA does not just cover work-from-home arrangements, but other types of work arrangements such as flexible working hours and flexible workloads.",
  "Flexible working arrangements (FWAs) should not be made mandatory as legislating them could lead to disputes and a bitter workplace culture, says a tripartite working group that recently announced guidelines for FWA applications.",
  "In a press conference on April 23, the tripartite working group also emphasized that the FWA does not just cover work-from-home arrangements, but other types of work arrangements such as flexible working hours and flexible workloads.",
  "Flexible working arrangements (FWAs) should not be made mandatory as legislating them could lead to disputes and a bitter workplace culture, says a tripartite working group that recently announced guidelines for FWA applications.",
  "In a press conference on April 23, the tripartite working group also emphasized that the FWA does not just cover work-from-home arrangements, but other types of work arrangements such as flexible working hours and flexible workloads.",
  "Flexible working arrangements (FWAs) should not be made mandatory as legislating them could lead to disputes and a bitter workplace culture, says a tripartite working group that recently announced guidelines for FWA applications.",
  "In a press conference on April 23, the tripartite working group also emphasized that the FWA does not just cover work-from-home arrangements, but other types of work arrangements such as flexible working hours and flexible workloads.",
];
