import { GoogleMapsContext, latLngEquals } from "@vis.gl/react-google-maps";
import type { Ref } from "react";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

type CircleEventCallback = (e: google.maps.MapMouseEvent) => void;

type CircleEventProps = {
  onClick?: CircleEventCallback;
  onDrag?: CircleEventCallback;
  onDragStart?: CircleEventCallback;
  onDragEnd?: CircleEventCallback;
  onMouseOver?: CircleEventCallback;
  onMouseOut?: CircleEventCallback;
  onRadiusChanged?: (r: ReturnType<google.maps.Circle["getRadius"]>) => void;
  onCenterChanged?: (p: ReturnType<google.maps.Circle["getCenter"]>) => void;
};

export type CircleProps = google.maps.CircleOptions & CircleEventProps;

export type CircleRef = Ref<google.maps.Circle | null>;

function useCircle(props: CircleProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onRadiusChanged,
    onCenterChanged,
    radius,
    center,
    ...circleOptions
  } = props;

  const callbacks = useRef<CircleEventProps>({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onRadiusChanged,
    onCenterChanged,
  });

  const circle = useRef<google.maps.Circle>(new google.maps.Circle()).current;

  circle.setOptions(circleOptions);

  useEffect(() => {
    if (!center) return;
    if (!latLngEquals(center, circle.getCenter())) circle.setCenter(center);
  }, [center, circle]);

  useEffect(() => {
    if (radius === undefined || radius === null) return;
    if (radius !== circle.getRadius()) circle.setRadius(radius);
  }, [radius, circle]);

  const map = useContext(GoogleMapsContext)?.map;

  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error("<Circle> has to be inside a Map component.");

      return;
    }

    circle.setMap(map);

    return () => {
      circle.setMap(null);
    };
  }, [circle, map]);

  useEffect(() => {
    const gme = google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(circle, eventName, (e: google.maps.MapMouseEvent) => {
        const callback = callbacks.current[
          eventCallback as keyof CircleEventProps
        ] as CircleEventCallback;
        if (typeof callback === "function") callback(e);
      });
    });
    gme.addListener(circle, "radius_changed", () => {
      const newRadius = circle.getRadius();
      if (typeof callbacks.current.onRadiusChanged === "function") {
        callbacks.current.onRadiusChanged(newRadius);
      }
    });
    gme.addListener(circle, "center_changed", () => {
      const newCenter = circle.getCenter();
      if (typeof callbacks.current.onCenterChanged === "function") {
        callbacks.current.onCenterChanged(newCenter);
      }
    });

    return () => {
      gme.clearInstanceListeners(circle);
    };
  }, [circle, callbacks]);

  return circle;
}

/**
 * Component to render a circle on a map
 */
const Circle = forwardRef((props: CircleProps, ref: CircleRef) => {
  const circle = useCircle(props);

  useImperativeHandle(ref, () => circle);

  return null;
});

Circle.displayName = "FoodMapCircle";

export default Circle;
