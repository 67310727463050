import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import Heading from "@elements/Typography/Heading";
import Link from "@elements/Typography/Link";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

const BH_SERVICES_MAIL = "bhservices@sph.com.sg";
const SERVICE_SECTIONS = [
  {
    title: "Translation",
    rateInfo: "English - Malay",
    rates: [
      { time: "< 3 hours", words: "max 500 words", price: "$1" },
      { time: "< 5 hours", words: "max 500 words", price: "$0.90" },
      { time: "< 24 hours", price: "$0.75" },
      { time: "< 48 hours", price: "$0.60" },
      { time: "More than 48 hours", price: "$0.50" },
    ],
  },
  {
    title: "Vetting",
    rateInfo: "English - Malay",
    rates: [
      { time: "< 3 hours", price: "$0.50" },
      { time: "< 5 hours", price: "$0.40" },
      { time: "< 24 hours", price: "$0.40" },
      { time: "< 48 hours", price: "$0.30" },
      { time: "More than 48 hours", price: "$0.30" },
    ],
  },
  {
    title: "Proofreading",
    rateInfo: "English - Malay",
    rates: [
      { time: "Standard rate", price: "$0.20" },
      { time: "More than 48 hours", price: "$0.50" },
    ],
  },
];

const PricingSection = ({
  title,
  rateInfo,
  rates,
}: (typeof SERVICE_SECTIONS)[0]) => (
  <div className="flex flex-col gap-[16px] w-full md:max-w-[320px] pt-[8px] lg:py-[24px]">
    <div className="flex justify-between items-center">
      <span className="font-secondary font-medium text-sm">{title}</span>
      <span className="text-xxs font-regular">{rateInfo}</span>
    </div>
    {rates.map(({ time, words, price }, index) => (
      <div
        key={index}
        className={`flex justify-between items-center border-b-[1px] border-white-600 pb-[8px] ${(index == 0 || index == 1) && "md:h-[45px]"}`}
      >
        <div className="flex flex-col">
          <span className="font-secondary text-xxs font-medium">{time}</span>
          {words && <span className="text-xxs font-regular">{words}</span>}
        </div>
        <div className="flex items-center gap-xxs">
          <span className="text-sm font-semimedium">{price}</span>
          <span className="text-xxs font-regular">per word</span>
        </div>
      </div>
    ))}
  </div>
);

export default function BHServices() {
  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();
  const sectionName = "BH Services";
  const uniqueSectionName = "bh-services";
  const pageAdTargetType = PageAdTargetingTypeEnum.LISTING;

  const basePageProps: BHBasePageProps = {
    uniqueSectionName,
    gaData: {
      level2: "miscellaneous",
      title: sectionName,
      section: "miscellaneous",
      contentcat: "1",
      contenttype: "listing",
    },
    metaTags: {
      title:
        "Berita Harian Services - Perkhidmatan Berita Harian | Beritaharian.sg",
      slug: uniqueSectionName,
      description:
        "Berita Harian Services - Dapatkan harga perkhidmatan Berita Harian hanya di",
    },
    pageAdTargetValue: pageAdTargetType,
  };

  const firstColumn = SERVICE_SECTIONS.slice(0, 2);
  const secondColumn = SERVICE_SECTIONS.slice(2, 3);

  const handleClick = () => {
    bhCustomEventAnalyticsCall("email", "click", window.location.href);
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="px-[16px] lg:px-0">
        <div className="flex flex-col gap-[24px] max-w-[860px] m-auto lg:mt-md mb-[32px] lg:border-t-2 border-blue-400 pt-xs">
          <Heading
            Element="h1"
            className="font-semimedium lg:font-semibold text-sm lg:text-xxs text-blue-400 border-b-2 border-blue-400 lg:border-b-0 pb-xs lg:pb-0"
          >
            BH Services
          </Heading>
          <div className="flex flex-col gap-[24px] lg:gap-0 text-black-800">
            <div className="flex flex-col md:flex-row gap-[24px] md:gap-[96px]">
              {firstColumn.map((section, index) => (
                <PricingSection key={index} {...section} />
              ))}
            </div>
            <div className="flex flex-col gap-[24px]">
              {secondColumn.map((section, index) => (
                <PricingSection key={index} {...section} />
              ))}
            </div>
          </div>
          <div className="text-xs text-blue-400 font-semibold leading-[21px]">
            <p>* $20 (minimum charge)</p>
            <p>
              *1 round of change for FOC. Subsequent changes will be charged at
              5 cents per word.
            </p>
            <p>* Rate is before 9% GST</p>
          </div>
          <div>
            <Link
              destination={`mailto:${BH_SERVICES_MAIL}`}
              relativePath={false}
              className="flex items-center justify-center w-[192px] h-[40px] bg-blue-400 text-white-500 text-xs font-regular"
              handleClick={handleClick}
            >
              <span>Email us for enquires</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full bg-white-200">
        <AdsLb2
          uniqueSectionName={uniqueSectionName}
          pageAdTargetType={pageAdTargetType}
          hasStripeBg={false}
          wrapperStyle="mb-0"
        />
      </div>
    </BHBasePage>
  );
}
