import SPHLogoFooter from "@assets/footer/newIcons/BHFooterLogoNew.svg";
import { MainContainer } from "@components/Container/MainContainer";
import type { ReactElement } from "react";

import { FooterCopyright } from "./FooterCopyright";
import {
  FOOTER_APP_DOWNLOAD_MENU,
  FOOTER_SOCIAL_MENU,
  FOOTER_SUB_MENU,
  FOOTER_TOP_MENU,
} from "./footerMenuConstants";
import { FooterMenuList } from "./FooterMenuList";

export type FooterProps = {
  testId?: string;
};

export function FooterNew({ testId = "footer" }: FooterProps): ReactElement {
  return (
    <>
      <div
        data-testid={`${testId}-footerContainer`}
        className="py-xl px-0 bg-blue-400 text-white-100"
      >
        <MainContainer className="!max-w-screen-cxl px-sm">
          <div className="flex flex-col md:flex-row gap-md md:gap-[65px]">
            <div className="basis-1/5 min-w-[240px]">
              <img src={SPHLogoFooter} alt="footer-logo" width={230}></img>
              <FooterMenuList
                testId={testId}
                items={FOOTER_SOCIAL_MENU}
                type="social"
              />
              <div className="flex flex-col gap-md lg:gap-lg">
                <FooterMenuList
                  testId={testId}
                  items={FOOTER_TOP_MENU}
                  type="top"
                />
                <div>
                  <FooterMenuList
                    testId={testId}
                    items={FOOTER_SUB_MENU}
                    type="eshop"
                  />
                  <h4 className="font-primary font-semibold text-xs">
                    Berita Harian di Mudah Alih
                  </h4>
                </div>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row xl:justify-between basis-4/5">
              <div className="lg:flex lg:flex-col lg:gap-xs md:pt-[90px] max-w-full xl:max-w-[350px] text-xs text-white-200  leading-[150%]">
                <FooterMenuList
                  testId={testId}
                  items={FOOTER_APP_DOWNLOAD_MENU}
                  type="appDownload"
                />
                <h4 className="font-primary px-0 my-sm font-semibold">
                  Khidmat pelangganan
                </h4>
                <a
                  href="callto:63883838"
                  className="font-semimedium hover:underline"
                >
                  6388-3838
                </a>
                <h4 className="font-primary my-sm px-0 font-semibold">
                  Meja Berita
                </h4>
                <p className="text-white-100 font-primaryLight mt-0 font-semibold mb-4">
                  Anda ada maklumat mengenai sesuatu berita menarik?
                  <br />
                  E-mel: bhnews@sph.com.sg{" "}
                </p>
              </div>
              <div className="lg:w-[390px] flex items-end xl:pb-[55px]">
                <FooterCopyright className="block" testId={testId} />
              </div>
            </div>
          </div>
        </MainContainer>
      </div>
    </>
  );
}
