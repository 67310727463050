import styles from "@components/HeaderNew/Search/Search.module.css";
import {
  isSameOrigin,
  prefixCheck,
  sanitizeSearchKeyword,
} from "@helper/utils";
import cx from "classnames";
import { ChangeEvent, KeyboardEvent, useState } from "react";

type SearchInputProps = {
  expanded: boolean;
  isMobile?: boolean;
  forModal?: boolean;
};

export function SearchInput({
  expanded,
  isMobile,
  forModal = false,
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const sanitized = sanitizeSearchKeyword(event.target.value);
    setSearchValue(sanitized);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!searchValue.trim()) return;
      if (prefixCheck(searchValue)) return;
      if (!isSameOrigin(window.origin)) return;
      window.location.href = `/search/site/${encodeURIComponent(searchValue)}`;
    }
  };

  return (
    <div
      data-testid="search"
      className={cx(
        styles.searchInner,
        { [styles.isMobile]: isMobile },
        { [styles.forModal]: forModal },
        "w-full lg:max-w-screen-lg xl:max-w-screen-cxl px-xs",
        {
          "py-[2px] duration-500 ease-in-out": isMobile,
          "opacity-100 h-[39px]": expanded && isMobile,
          "opacity-0 h-0 !py-0 invisible": !expanded && isMobile,
        },
      )}
    >
      <div data-testid="searchExpanded" className={cx(styles.searchExpanded)}>
        <input
          type="search"
          placeholder="Kata Carian..."
          className={cx(styles.searchInput, "text-black-700")}
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={handleSearch}
        />
      </div>
    </div>
  );
}
