import DesktopMainImg from "@assets/wordpuzzle/Desktop_kuiz-bh.svg";
import MobileMainImg from "@assets/wordpuzzle/Mobile_kuiz-bh.svg";

import QuestionCard from "./QuestionCard";

export type Questions = { [key: string]: string }[];

type ResultsProps = {
  isMobile: boolean;
  questionNum: number;
  questions: Questions;
  englishWord: string;
  options: string[];
  selectedWord: string | null;
  correctMalayWord: string;
  handleWordClick: (word: string) => void;
  resultVisible: boolean;
};

function QuestionDetailSection({
  isMobile,
  questionNum,
  questions,
  englishWord,
  options,
  selectedWord,
  correctMalayWord,
  handleWordClick,
  resultVisible,
}: ResultsProps): JSX.Element {
  const progressPercentage = (questionNum / 10) * 100;
  return (
    <>
      {!isMobile ? (
        <div className="flex flex-col items-center justify-center bg-gradient-to-r from-white-700 to-white-300">
          <div className="flex w-full h-[174px] justify-center relative">
            <img
              src={DesktopMainImg}
              width={695}
              height={200}
              alt="Man Icon"
              className="block absolute -top-5"
            />
          </div>
          {/* Progress Bar */}
          {!resultVisible && (
            <div className="flex w-full max-w-[1100px] gap-xs px-xxs items-center">
              <div className="w-full bg-grey-300 rounded-[8px] h-[8px]">
                <div
                  className="bg-blue-100 h-[8px] rounded-[8px]"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <div className="text-sm text-blue-100 leading-[19px]">
                {questionNum}/10
              </div>
            </div>
          )}
          {questions.length > 0 && !resultVisible && (
            <QuestionCard
              englishWord={englishWord}
              options={options}
              selectedWord={selectedWord}
              correctMalayWord={correctMalayWord}
              onWordClick={handleWordClick}
            />
          )}
        </div>
      ) : (
        <div className="flex w-full flex-col justify-center">
          <div className="flex items-center justify-center bg-white-300 w-full">
            <img src={MobileMainImg} alt="logo" width={345} height={156} />
          </div>
          <div className="flex flex-col w-full">
            {/* Progress Bar */}
            {!resultVisible && (
              <div className="flex w-full max-w-[1100px] gap-xs px-xs items-center">
                <div className="w-full bg-blue-50 rounded-[8px] h-[8px]">
                  <div
                    className="bg-blue-100 h-[8px] rounded-[8px]"
                    style={{ width: `${progressPercentage}%` }}
                  />
                </div>
                <div className="text-xxs text-blue-100 leading-[19px]">
                  {questionNum}/10
                </div>
              </div>
            )}
            {questions.length > 0 && !resultVisible && (
              <QuestionCard
                englishWord={englishWord}
                options={options}
                selectedWord={selectedWord}
                correctMalayWord={correctMalayWord}
                onWordClick={handleWordClick}
                isMobile={isMobile}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default QuestionDetailSection;
