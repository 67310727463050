import { ReactNode } from "react";
export type LinkProps = {
  destination: string;
  children: ReactNode;
  className?: string;
  relativePath?: boolean;
  target?: "_self" | "_blank";
  rel?: "nofollow" | "";
  type?: string;
  transformCase?: boolean;
  handleClick?: () => void;
  testId?: string;
};

export default function Link({
  destination,
  className,
  children,
  relativePath = true,
  target = "_self",
  rel = "",
  type,
  transformCase = false,
  handleClick,
  testId = "link",
}: LinkProps) {
  const href = transformCase ? destination.toLowerCase() : destination;
  return (
    <a
      href={relativePath ? `/${href}` : href}
      className={className ?? `text-sm text-grey-400`}
      target={target}
      rel={rel}
      type={type}
      onClick={handleClick}
      data-testid={testId}
    >
      {children}
    </a>
  );
}
