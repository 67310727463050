import { MISC_LIST } from "@components/HeaderNew/headerMenuConstants";
import { HeaderMenuList } from "@components/HeaderNew/HeaderMenuList";
import type { ReactElement } from "react";
import { MouseEventHandler } from "react";

export type MiscLinksProps = Readonly<{
  testId?: string;
  mySPHClickHandler?: MouseEventHandler<HTMLButtonElement>;
}>;

export function MiscLinks({
  testId = "desktop-misc-links",
}: MiscLinksProps): ReactElement {
  return <HeaderMenuList testId={testId} items={MISC_LIST} type="misc" />;
}
