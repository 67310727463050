export const SgCoords = {
  lat: {
    top: 1.4701324,
    bot: 1.2402414,
  },
  lang: {
    left: 103.6154013,
    right: 104.0329373,
  },
};

export const iOSWarningImage =
  "https://static.beritaharian.sg/s3fs-public/ios-fb-inapp.png";
export const androidWarningImage =
  "https://static.beritaharian.sg/s3fs-public/android-fb-inapp.jpg";
