import SortRestaurantsOnDistance from "@components/FoodMap/helper/sortRestaurantsOnDistance";
import UpdateRestaurantDistance from "@components/FoodMap/helper/updateRestaurantDistance";
import UpdateRestaurantIndex from "@components/FoodMap/helper/updateRestaurantIndex";
import { getErrorMessage, reportError } from "@helper/getErrorMessage";
import type {
  Point,
  ResponseFoodMapData,
  RestaurantDataWithDistance,
} from "@typings/foodMapTypes";

import restaurantData from "./restaurant-data.json";

export default function FetchRestaurants(
  userLocation: Point,
): RestaurantDataWithDistance[] | null {
  let finalResponse = null;
  try {
    const data = (restaurantData as ResponseFoodMapData).data;
    const response = UpdateRestaurantDistance({
      data: data,
      userLocation: userLocation,
    });
    finalResponse = SortRestaurantsOnDistance(response);
    finalResponse = UpdateRestaurantIndex(finalResponse);
  } catch (error) {
    const err = getErrorMessage(error);
    reportError({ message: `Error getting foodmap data: ${err}` });
  }
  return finalResponse;
}
