import { getCurrentDate } from "@helper/useDateFormat";

type FooterCopyrightProps = {
  testId?: string;
  className: string;
};

export function FooterCopyright({ className, testId }: FooterCopyrightProps) {
  const year = getCurrentDate("yyyy");
  return (
    <div
      data-testid={`${testId}-copyright`}
      className={`${className} text-xxs text-white-200 font-regular`}
    >
      <p className="p-0">
        MCI (P) 020/06/2024, MCI (P) 019/06/2024 SPH Digital News Copyright ©{" "}
        <span>{year}</span> . SPH Media Limited. Co. Regn. No. 202120748H
      </p>
    </div>
  );
}
