import "../ScrollBarStyle.css";

import podcastIcon from "@assets/images/Podcast.svg";
import videoIcon from "@assets/images/YouTube.svg";
import MediaThumbnail from "@components/HomePageStoryPackages/MediaPackages/MediaThumbnail";
import MoreLink from "@components/HomePageStoryPackages/MoreLink";
import Heading from "@elements/Typography/Heading";
import { MoreLinkProps } from "@typings/StoryPackage.d";
import { twMerge } from "tailwind-merge";

interface ThumbnailItem {
  published_at?: string;
  name?: string;
  thumbnail?: string;
  src?: string;
  id?: string;
}
export type MediaCardProps<T> = {
  variant: "Podcasts" | "Videos";
  renderedThumbnail?: (T & ThumbnailItem)[];
  handleOnClickCallback: (source?: string) => void;
  moreLink: MoreLinkProps;
};

export default function MediaCard<T>({
  variant,
  renderedThumbnail,
  handleOnClickCallback,
  moreLink,
}: MediaCardProps<T>) {
  return (
    <div className="my-sm py-xs border border-blue-400 border-x-0 border-b-0 border-t-2">
      <div className="flex items-center gap-xs font-primary text-sm font-semibold text-blue-400 leading-[24px] mb-xs pl-xxs">
        <img
          src={variant === "Podcasts" ? podcastIcon : videoIcon}
          alt="media-icon"
          width={20}
          height={20}
        />
        <Heading className="font-primary">{variant}</Heading>
      </div>

      <div className="overflow-x-scroll scrollBarStyles pb-md">
        <div className="flex gap-[10px] w-[2150px]">
          {renderedThumbnail &&
            renderedThumbnail.map((item, index) => (
              <button
                className="flex flex-col gap-sm text-left basis-[17%] break-words overflow-auto"
                key={index}
                onClick={() => {
                  handleOnClickCallback(item.src ? item.src : item.id);
                }}
              >
                <MediaThumbnail
                  src={item.thumbnail}
                  height={variant === "Videos" ? 155 : 206}
                />
                <div className="flex flex-col gap-sm">
                  {item.name && (
                    <Heading className="lg:text-sm font-medium font-secondary text-black-800 leading-[19.2px] hover:text-blue-400">
                      {item.name}
                    </Heading>
                  )}
                  {item.published_at && (
                    <span className="text-xs text-black-700">
                      {item.published_at}
                    </span>
                  )}
                </div>
              </button>
            ))}
        </div>
      </div>

      <div className="flex justify-end leading-[18px] text-grey-350 font-semibold font-primary border-white-600 border-t-[1px] pt-xs">
        <MoreLink
          {...moreLink}
          className={twMerge("flex gap-1", moreLink.className)}
        />
      </div>
    </div>
  );
}
