import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type WidgetWrapperProps = {
  children: ReactNode;
  className?: string;
};

export default function WidgetWrapper({
  children,
  className,
}: WidgetWrapperProps) {
  return (
    <div
      data-testid="widget-wrapper"
      className={twMerge(
        "lg:max-w-screen-lg xl:max-w-screen-cxl mx-auto px-[10px] lg:px-[5px]",
        className,
      )}
    >
      {children}
    </div>
  );
}
