import ArticleCardCarousel from "@components/Carousel/ArticleCardCarousel";
import CASBlock from "@components/CASBlock/CASBlock";
import SideBlockAds from "@components/Container/SideBlockAds";
import ArticleCard from "@elements/Card/Article/ArticleCard";
import { RenderTags } from "@elements/Typography/Tags";
import { CAS_CDP_HOME_ID } from "@helper/getEnvVariables";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import React from "react";

import { HomeSections } from "./HomeSections";

export type HomePageOldProps = {
  topStoryQueue: ProcessedArticleData;
  subStoryQueue: ProcessedArticleData[];
  adsSectionName: string;
  pageAdTargetValue: PageAdTargetingTypeEnum;
  latestRegisteredArticle: ProcessedArticleData[];
  latestFreeArticle: ProcessedArticleData[];
  excludeIds: string;
};

export default function HomePageOld({
  topStoryQueue,
  subStoryQueue,
  adsSectionName,
  pageAdTargetValue,
  latestRegisteredArticle,
  latestFreeArticle,
  excludeIds,
}: HomePageOldProps) {
  return (
    <>
      <div className="w-full mb-4 lg:border-b lg:border-grey-850">
        <ArticleCard
          displayWidth={833}
          displayHeight={555}
          elements={{
            hasIcon: true,
            hasSectionName: true,
            hasParagraph: true,
            hasTags: false,
          }}
          overrideStyle={{
            imageStyle: "w-full",
            cardWrapper: "flex flex-col-reverse md:flex-row-reverse gap-sm",
            headerWrapper: "w-full md:w-8/12",
            contentWrapper: "w-full md:flex-col md:w-4/12",
            titleStyle: "text-lg md:text-lg leading-lg font-semibold md:mb-2",
            tagWrapper: "hidden md:flex md:py-2",
            description: "hidden md:block",
          }}
          htmlElements={{
            headingElement: "h1",
          }}
          {...topStoryQueue}
        />
        <div className="flex flex-col">
          <span
            data-testid="paragraph"
            className={
              "font-primary text-xss md:text-xs leading-[150%] font-regular mb-sm md:hidden"
            }
          >
            {topStoryQueue.paragraph}
          </span>
          <RenderTags
            tags={topStoryQueue.tags}
            tagsWrapper="flex gap-2 md:hidden"
          />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col md:flex-row w-full gap-4">
          {subStoryQueue.map((context) => {
            return (
              <div
                className="w-full lg:border-b lg:border-grey-850"
                key={context.id}
              >
                <ArticleCard
                  displayWidth={454}
                  displayHeight={303}
                  elements={{
                    hasIcon: true,
                    hasSectionName: true,
                    hasParagraph: true,
                    hasTags: false,
                    hasDateSideBySide: true,
                  }}
                  overrideStyle={{
                    headerWrapper: "basis-4/12",
                    contentWrapper: "basis-8/12",
                    imageStyle: "w-full",
                    cardWrapper: "flex md:flex-col gap-2 md:gap-4",
                    sectionNameWrapper: "flex-col-reverse items-start gap-1",
                    tagWrapper: "hidden lg:flex gap-2",
                    dateStyle: "font-regular lg:text-xs",
                    description: "hidden md:flex",
                    hero: "mb-0",
                  }}
                  {...context}
                />
              </div>
            );
          })}
        </div>
        <SideBlockAds
          uniqueSectionName={adsSectionName}
          adsOnly={true}
          hasImu3={false}
          className="min-w-[300px] w-auto"
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
      <ArticleCardCarousel
        renderItems={latestRegisteredArticle}
        className="mt-sm"
      />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-lg mt-xxl">
        {latestFreeArticle.map((context) => {
          return (
            <React.Fragment key={context.id}>
              <div className="col-span-1">
                <ArticleCard
                  displayWidth={282}
                  displayHeight={188}
                  elements={{
                    hasIcon: true,
                    hasSectionName: true,
                    hasParagraph: true,
                    hasDateSideBySide: true,
                  }}
                  overrideStyle={{
                    headerWrapper: "basis-4/12",
                    contentWrapper: "basis-8/12",
                    cardWrapper: "flex md:flex-col gap-2 w-full",
                    imageStyle: "w-full",
                    sectionNameWrapper: "flex-col-reverse items-start gap-1",
                    tagWrapper: "hidden lg:flex",
                    dateStyle: "font-regular text-grey-400",
                    description: "hidden sm:flex",
                    sectionNameItem: "lg:justify-start",
                    titleStyle: "text-sm",
                  }}
                  {...context}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <CASBlock casId={CAS_CDP_HOME_ID} />
      <HomeSections
        adsSectionName={adsSectionName}
        renderedArticleIds={excludeIds}
      />
    </>
  );
}
