import PodcastGallery, {
  PodcastBHCustomEventCategory,
} from "@components/Gallery/PodcastGallery";
import VideoGallery from "@components/Gallery/VideoGallery";
import TranslationCTA from "@components/Translation/TranslationCTA";
import TranslationPopover from "@components/Translation/TranslationPopover";
import WordPuzzle from "@components/WordPuzzle/WordPuzzle";
import { getTagsName } from "@elements/GAData/GADataHelper";
import { ARTICLE_TRANSLATE_CTA } from "@helper/getEnvVariables";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import BHArticleBasePage, {
  AdsSettings,
  BHArticleBasePageProps,
} from "@pages/BHArticleBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import { useState } from "react";

import { PayloadResponseType } from "./Article.server";
import ArticleContentCard from "./ArticleContentCard";
import ArticleInfiniteScroll from "./ArticleInfiniteScroll";
import ArticleMetaData from "./ArticleMetaData";

export function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const adsSettings: AdsSettings = useGlobalAdSetting();
  const { gsChannels } = useGrapeshotData(
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? (dataLoaderResponse.payload.article.urlPath as string)
      : "",
  );

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  if (dataLoaderResponse.type === ResponseType.SUCCESS) {
    const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
    const mainArticle = dataLoaderResponse.payload.article;
    const uniqueSectionName = mainArticle.uniqueSectionName;
    const latestRegisteredArticle =
      dataLoaderResponse.payload.latestRegisteredArticle;

    const handleNewArticle = (newArticle: ProcessedArticleData) => {
      setCurrentArticle(newArticle);
    };

    const { gaData, metaTags } = ArticleMetaData({
      article: currentArticle || mainArticle,
      isMainArticle: currentArticle ? false : true,
      gsChannels:
        currentArticle && currentArticle.gsChannels
          ? currentArticle.gsChannels
          : gsChannels,
    });

    const bhTags = getTagsName(mainArticle.tags || []);
    const bhArticleId = mainArticle.id;
    const articleSlotTarget: AdTargetingType<ArticleAdTargetKeys, string>[] = [
      { key: ArticleAdTargetKeys.ARTICLE_ID, value: bhArticleId },
      { key: ArticleAdTargetKeys.TAGS, value: bhTags.toLowerCase() },
    ];

    const basePageProps: BHArticleBasePageProps = {
      uniqueSectionName,
      pageAdTargetValue,
      gaData,
      metaTags,
      suppliedAdsSettings: adsSettings,
      gsChannels,
      articleSlotTarget,
    };

    return (
      <>
        <BHArticleBasePage {...basePageProps}>
          <div id={mainArticle.id} className="article-card">
            <ArticleContentCard
              mainArticle={mainArticle}
              latestRegisteredArticle={latestRegisteredArticle}
              pageAdTargetValue={pageAdTargetValue}
              pageNumber={0}
              isInfiniteScrollArticle={false}
              isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
              gsChannels={gsChannels}
              articleSlotTarget={articleSlotTarget}
            />
          </div>
          <VideoGallery />
          <PodcastGallery
            bhCustomEventProps={{
              eventCategory: PodcastBHCustomEventCategory.ARTICLE_PAGE,
              eventAction: "click",
            }}
          />
          <ArticleInfiniteScroll
            mainArticle={mainArticle}
            latestRegisteredArticle={latestRegisteredArticle}
            pageAdTargetValue={pageAdTargetValue}
            onNewArticle={handleNewArticle}
            isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
          />
          <TranslationPopover
            gsChannels={gsChannels}
            articleSlotTarget={articleSlotTarget}
          />
        </BHArticleBasePage>
        {parseInt(ARTICLE_TRANSLATE_CTA) ? (
          <TranslationCTA
            article={currentArticle ? currentArticle : mainArticle}
          />
        ) : null}
        <WordPuzzle />
      </>
    );
  }
}
