export const isArrayWithElements = (payload: unknown): payload is unknown[] => {
  return Array.isArray(payload) && payload.length >= 1;
};

export const getCookieByName = (name: string): string | undefined => {
  if (typeof window === "undefined") {
    return;
  }
  const match = document.cookie.match(new RegExp(name + "=([^;]+)"));
  return match ? match[1] : undefined;
};

export const isSameOrigin = (origin: string): boolean => {
  if (origin === "http://localhost:3000") return true;
  const matches = origin.match(/([^\\/.]+)\.sg/);
  if (matches && matches.length > 1) {
    return matches[1] === "beritaharian";
  }
  return false;
};

export const sanitizeSearchKeyword = (searchValue: string) => {
  const sanitizedInput = searchValue.replace(/[^\w\s]/gi, "").substring(0, 255);
  return sanitizedInput;
};

export const prefixCheck = (url: string): boolean => {
  if (url.startsWith("/") && !url.includes("://")) {
    return true;
  }
  return false;
};

import { ClientSideSecrets, FeatureFlags } from "@app/featureFlag/featureFlags";
import { SgCoords } from "@components/FoodMap/FoodMapConstants/FoodMapConstants";
import {
  NON_HEADER,
  SECONDARY_SECTION_LIST,
  SECTION_LIST,
} from "@components/Header/headerMenuConstants";
import {
  NON_HEADER_NEW,
  SECONDARY_SECTION_LIST_NEW,
  SECTION_LIST_NEW,
} from "@components/HeaderNew/headerMenuConstants";
import type { Point } from "@typings/foodMapTypes";

import fetchBHApi from "./fetchBHApi";
import { BH_BASE_URL, ENVIRONMENT } from "./getEnvVariables";
//iteration determine how frequent it would return true, useful for ads.
export const displayAfter = (index: number, iteration: number): boolean => {
  return index > 0 && index % iteration === 0;
};

export async function getFeatureFlag(
  key: FeatureFlags | ClientSideSecrets,
): Promise<string> {
  type Response = {
    Key: string;
  };
  const response: Response = await fetchBHApi("get-feature-flag", "POST", {
    key: key,
  });

  return response.Key;
}

export const findStyleBySection = (
  uniqueSectionName: string | undefined,
): { image?: string; selectedColor?: string } | undefined => {
  const sections = [...SECONDARY_SECTION_LIST, ...SECTION_LIST, ...NON_HEADER];

  const result = sections.find(
    ({ url }) => url.slice(1).toLowerCase() === uniqueSectionName,
  );
  return { image: result?.images, selectedColor: result?.selectedColor };
};

export const findStyleBySectionNew = (
  uniqueSectionName: string | undefined,
): { image?: string; selectedColor?: string } | undefined => {
  const sections = [
    ...SECONDARY_SECTION_LIST_NEW,
    ...SECTION_LIST_NEW,
    ...NON_HEADER_NEW,
  ];

  const result = sections.find(
    ({ url }) => url.slice(1).toLowerCase() === uniqueSectionName,
  );
  return { image: result?.images, selectedColor: result?.selectedColor };
};

//Returns https://uat.beritaharian.sg as example
export const getWindowOrigin = (): string => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  } else if (ENVIRONMENT === "local") {
    return "http://localhost:3000";
  }
  return BH_BASE_URL;
};

export const isClientSide: boolean = typeof window !== "undefined";
export const isProd = "https://www.beritaharian.sg" === getWindowOrigin();

/**
 * Description of the function.
 *
 * @param {Type} callback - Callback for this function.
 * @param {Type} maxRetries - No. of tries wanted to do.
 * @param {Type} initialDelayMs - Initial delay for each retry.
 * @param {Type} maxDelayMs - Maximum allowed delay.
 */

export async function fetchWithRetry<T>(
  callback: () => Promise<T>,
  maxRetries: number,
  initialDelayMs: number,
  maxDelayMs: number,
): Promise<T> {
  let retries = 0;
  let delay = initialDelayMs;

  while (retries < maxRetries) {
    try {
      return await callback();
    } catch (error) {
      retries++;
      if (retries >= maxRetries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
      delay = Math.min(delay * 2, maxDelayMs);
    }
  }

  throw new Error("Max retries exceeded");
}

export function getValidEmailRegex() {
  return /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
}

export function betweenCoords(x: number, min: number, max: number) {
  return x >= min && x <= max;
}

export const isLocationInSingapore = (location: Point) =>
  betweenCoords(location.lat, SgCoords.lat.bot, SgCoords.lat.top) &&
  betweenCoords(location.lng, SgCoords.lang.left, SgCoords.lang.right);

export function roundToFourDecimals(value: number): number {
  return Math.round(value * 10000) / 10000;
}

/**
 * Helper function to check if URL is a URL from the specified from parameter.
 * @param url URL to check.
 * @returns {boolean}
 */
export const isThisUrlFrom = (url: string, from: string): boolean => {
  const domain = new URL(url).hostname;
  return domain.includes(from);
};

export function getRandomValue() {
  // use crypto.getRandomValues() instead of Math.random()
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  const randomValue = array[0] / 2 ** 32; // normalize to [0, 1)

  return randomValue;
}
