import SideBlockAds from "@container/SideBlockAds";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import NoResult from "./NoResult";
import type {
  SearchErrorResponseType,
  SearchPayloadResponseType,
} from "./Search.server";
import SearchMetaData from "./SearchMetaData";
import SearchResultList from "./SearchResultList";

export function SearchResultPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<SearchPayloadResponseType, SearchErrorResponseType>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const searchedText: string = dataLoaderResponse.payload.searchedText;
  const keywordArticles: ProcessedArticleData[] | [] =
    dataLoaderResponse.payload.data;

  const adsSectionName = "search";
  const { gaData, metaTags } = SearchMetaData(searchedText);

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const basePageProps: BHBasePageProps = {
    uniqueSectionName: adsSectionName,
    gaData: gaData,
    metaTags: metaTags,
    pageAdTargetValue: pageAdTargetValue,
  };

  return (
    <BHBasePage {...basePageProps}>
      <div className="max-w-screen-cxl m-auto flex flex-col lg:flex-row w-full gap-4">
        {keywordArticles.length === 0 ? (
          <NoResult searchedText={searchedText} />
        ) : (
          <SearchResultList
            articles={keywordArticles}
            searchedText={searchedText}
            uniqueSectionName={adsSectionName}
          />
        )}
        <SideBlockAds
          uniqueSectionName={adsSectionName}
          adsOnly={true}
          pageAdTargetingProps={pageAdTargetValue}
        />
      </div>
    </BHBasePage>
  );
}
