import SignupForm from "@components/Blocks/NewsLetterSignupForm/NewsLetterSignupForm";
import SideBlockAds from "@container/SideBlockAds";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import styles from "./Newsletter.module.css";
export function NewsletterPage() {
  const sectionName = "eNewsLetter";
  const newsletterPageProps: BHBasePageProps = {
    uniqueSectionName: sectionName,
    pageAdTargetValue: PageAdTargetingTypeEnum.LISTING,
  };

  return (
    <BHBasePage {...newsletterPageProps}>
      <div className="max-w-screen-xl m-auto flex flex-col lg:flex-row">
        <div className="flex lg:px-sm flex-col w-12/12 lg:w-8/12 w-full">
          <span className="text-blue-300 text-lg font-semibold">
            Langgani E-Newsletter
          </span>
          <div className="w-full pt-sm">
            <div className={styles.NewsletterContainer}>
              <img
                className={styles.imgFluid}
                src="https://static.beritaharian.sg/s3fs-public/Newsletter.png"
                alt="newsletter"
              />
              <h3 className={styles.author}>
                Langgani ringkasan percuma Berita Harian - Langsung ke emel
                anda.{" "}
              </h3>
              <SignupForm />
            </div>
          </div>
        </div>
        <SideBlockAds
          pageAdTargetingProps={PageAdTargetingTypeEnum.LISTING}
          adsOnly={true}
        />
      </div>
    </BHBasePage>
  );
}
