import ThumbnailWrapper from "@components/Container/ThumbnailWrapper";
import VideoDialog from "@components/Elements/Player/VideoDialog";
import { type LatestVideoResponse } from "@hooks/transformer/useBrightcoveResponse";
import useSwrApi from "@hooks/useSwrApi";
import { useState } from "react";

export default function VideoGallery() {
  const { data: videos } = useSwrApi<LatestVideoResponse[]>("latest-videos");

  const [videoDialog, setVideoDialog] = useState({
    visible: false,
    source: "",
  });

  const openVideoDialog = (source?: string) => {
    setVideoDialog({
      visible: true,
      source: source || "",
    });
  };

  const closeVideoDialog = (source?: string) => {
    setVideoDialog({
      visible: false,
      source: source || "",
    });
  };

  return (
    <>
      {videos ? (
        <>
          <ThumbnailWrapper<LatestVideoResponse>
            variant="videos"
            renderedThumbnail={videos.slice(0, 4)}
            handleOnClickCallback={openVideoDialog}
          />
          <VideoDialog
            videoDialog={videoDialog}
            handleOnClickCloseDialog={closeVideoDialog}
          />
        </>
      ) : null}
    </>
  );
}
