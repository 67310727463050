import { ChangeEvent, useEffect, useRef, useState } from "react";

import styles from "./Slider.module.css";

type FoodMapDistanceSliderProps = {
  initialDistance: number;
  onDistanceChange: (e: number) => void;
};

export default function FoodMapDistanceSlider({
  initialDistance = 1000,
  onDistanceChange,
}: FoodMapDistanceSliderProps) {
  const [distance, setDistance] = useState<number>(initialDistance);

  const distanceSliderRef = useRef<HTMLInputElement>(null);

  const handleDistanceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDistance = parseInt(event.target.value);
    setDistance(newDistance);
  };

  const handleDistanceChangeComplete = () => {
    if (distanceSliderRef.current) {
      const newDistance = parseInt(distanceSliderRef.current.value);
      onDistanceChange(newDistance);
    }
  };

  const formatDistanceText = (distanceValue: number) => {
    if (distanceValue >= 1000) {
      return `${(distanceValue / 1000).toFixed(1)} KM`;
    } else {
      return `${distanceValue} Meters`;
    }
  };

  useEffect(() => {
    const sliderElement = distanceSliderRef.current;
    if (sliderElement) {
      sliderElement.value = distance.toString();
    }
  }, [distance]);

  return (
    <div className="flex flex-col shadow-distSlider">
      <div className="pt-2 m-auto text-xs">
        Search Distance From Your Location ={" "}
        <b className="">{formatDistanceText(distance)}</b>
      </div>
      <div className="flex gap-3 items-center justify-between py-4 px-2">
        <span className="text-xxs">100 M</span>
        <input
          type="range"
          min="100"
          max="1000"
          value={distance}
          step="100"
          className={`w-[75%] h-[1px] bg-grey-750 transition-opacity duration-[0.2s] ${styles.slider}`}
          ref={distanceSliderRef}
          onChange={handleDistanceChange}
          onMouseUp={handleDistanceChangeComplete}
          onTouchEnd={handleDistanceChangeComplete}
        />
        <span className="text-xxs">1 KM</span>
      </div>
    </div>
  );
}
