import fetchBHApi from "@src/app/helper/fetchBHApi";

import { Questions } from "../QuestionDetailSection";

interface PuzzleData {
  [key: string]: Questions;
}
export const fetchPuzzleData = async (
  formattedDate: string,
): Promise<Questions | null> => {
  try {
    const response = await fetchBHApi<PuzzleData>(
      "feeds/puzzle-dedicated",
      "GET",
    );
    const result = response[formattedDate];
    return result;
  } catch (error) {
    console.error("Error fetching puzzle data:", error);
    return null;
  }
};
