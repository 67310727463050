import type { Point } from "@typings/foodMapTypes";

import { deg2rad, rad2deg } from "./helper";

type getDistanceProps = {
  source: Point;
  destination: Point;
  unit?: string;
};

/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/
/*::                                                                         :*/
/*::  This routine calculates the distance between two points (given the     :*/
/*::  latitude/longitude of those points). It is being used to calculate     :*/
/*::  the distance between two locations using GeoDataSource(TM) Products    :*/
/*::                                                                         :*/
/*::  Definitions:                                                           :*/
/*::    South latitudes are negative, east longitudes are positive           :*/
/*::                                                                         :*/
/*::  Passed to function:                                                    :*/
/*::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :*/
/*::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :*/
/*::    unit = the unit you desire for results                               :*/
/*::           where: 'M' is statute miles (default)                         :*/
/*::                  'K' is kilometers                                      :*/
/*::                  'N' is nautical miles                                  :*/
/*::  Worldwide cities and other features databases with latitude longitude  :*/
/*::  are available at https://www.geodatasource.com                         :*/
/*::                                                                         :*/
/*::  For enquiries, please contact sales@geodatasource.com                  :*/
/*::                                                                         :*/
/*::  Official Web site: https://www.geodatasource.com                       :*/
/*::                                                                         :*/
/*::         GeoDataSource.com (C) All Rights Reserved 2018                  :*/
/*::                                                                         :*/
/*::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::*/

export function getDistance({
  source,
  destination,
  unit = "K",
}: getDistanceProps): number {
  const lat1 = source.lat;
  const lng1 = source.lng;
  const lat2 = destination.lat;
  const lng2 = destination.lng;

  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  } else {
    const theta = lng1 - lng2;
    let dist =
      Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.cos(deg2rad(theta));
    dist = Math.acos(dist);
    const distInDegrees = rad2deg(dist);
    const miles = distInDegrees * 60 * 1.1515;
    const unitUppercase = unit.toUpperCase();

    if (unitUppercase === "K") {
      return miles * 1.609344;
    } else if (unitUppercase === "N") {
      return miles * 0.8684;
    } else {
      return miles;
    }
  }
}
