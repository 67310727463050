import { replaceDashAndWithSpace } from "@helper/stringTransform";
import { findStyleBySection, findStyleBySectionNew } from "@helper/utils";
import { twMerge } from "tailwind-merge";

import Link from "./Link";

export type SectionTitleElement = "h1" | typeof Link;
type SectionTitleProps = {
  sectionName?: string;
  uniqueSectionName?: string;
  className?: string;
  Element?: SectionTitleElement;
  forNewDesign?: boolean;
};

export function SectionTitle({
  sectionName,
  uniqueSectionName = sectionName,
  className,
  Element = "h1",
  forNewDesign,
}: SectionTitleProps) {
  const { selectedColor } = forNewDesign
    ? findStyleBySectionNew(uniqueSectionName) || {}
    : findStyleBySection(uniqueSectionName) || {};

  return sectionName ? (
    <Element
      destination={uniqueSectionName || ""}
      className={twMerge(
        "text-lg text-primary font-regular capitalize mb-2",
        `text-${selectedColor}`,
        className,
      )}
    >
      {replaceDashAndWithSpace(sectionName)}
    </Element>
  ) : null;
}
