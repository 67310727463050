export type MoreLinkProps = {
  destination: string;
  linkText?: string;
  className?: string;
  relativePath?: boolean;
  target?: "_self" | "_blank";
  testId?: string;
};

export enum HomeWidgetType {
  VERTICAL = "VerticalStoryPackage",
  HORIZONTAL = "HorizontalStoryPackage",
}

export enum FilterArticlesBasedOn {
  SECTION = "sectionUniqueName",
  TAGS = "tagAliases",
  PAYWALL = "contentAccess",
  LATEST_NEWS = "breakingNews",
}

export type StoryPackageConfig = {
  type: HomeWidgetType;
  title: string;
  filterArticlesBasedOn: FilterArticlesBasedOn;
  moreLink: MoreLinkProps;
  filterText?: string;
};
