import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { useSubscribeLink } from "@helper/checkMobileAppLink";
import cx from "classnames";
import React from "react";

import { FooterMenuDetails } from "./footerMenuConstants";
import { FooterMenuType } from "./footerMenuType";

type FooterMenuListProps = {
  testId: string;
  items: FooterMenuDetails[];
  type: FooterMenuType;
};

export function FooterMenuList({ testId, items, type }: FooterMenuListProps) {
  const subLink = useSubscribeLink();
  return (
    <List
      testId={`${testId}-${type}-footerMenuList`}
      style={cx(
        "text-white-100",
        type === "social" && "flex items-center gap-xxs mt-xs",
        type === "top" && "mt-sm [&_a]:font-semimedium",
        type === "eshop" && "[&_a]:font-semibold",
        type === "appDownload" && "flex mt-xs gap-[12px]",
      )}
    >
      {items.map((item: FooterMenuDetails, index: number) => (
        <React.Fragment key={index}>
          {!(subLink === "" && item.title === "Langgan") && (
            <>
              <ListItem
                key={`${testId}-item-${index}`}
                testId={`${testId}-item-${index}`}
              >
                <a
                  key={index}
                  href={item.url}
                  target={item.newTab ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className={cx(
                    "text-xs font-primary text-white-200 lead-[150%] font-thin hover:underline",
                    type === "social" &&
                      index === 4 &&
                      "w-[40px] flex justify-end",
                  )}
                >
                  {item.title ? item.title : null}
                  {item.images ? (
                    <img src={item.images} alt={`${item.title}-menu-icon`} />
                  ) : null}
                </a>
              </ListItem>
            </>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
