import WrapperContainer from "@elements/ArticleParagraph/ParagraphElements/WrapperContainer";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { InstagramEmbed, InstagramEmbedProps } from "react-social-media-embed";

import { SOCIAL_EMBED_WIDTH } from "./constants";

type InstagramEmbedCustomProps = {
  url: InstagramEmbedProps["url"];
  className?: string;
};
export default function InstagramEmbedCustom({
  url,
  className = "",
}: InstagramEmbedCustomProps): React.ReactElement {
  const { isScreenMD } = useWindowSize();

  return (
    <WrapperContainer className={className}>
      <InstagramEmbed
        url={url}
        width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
      />
    </WrapperContainer>
  );
}
