import WordPuzzlePage from "@components/Kuiz/WordPuzzle";
import BHBasePage, { BHBasePageProps } from "@pages/BHBasePage";
import WidgetWrapper from "@pages/Home/HomePageLayout/WidgetWrapper";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import KuizMetaData from "./KuizMetaData";

export default function KuizPage() {
  const sectionName = "kuiz-bh";
  const { gaData, metaTags } = KuizMetaData({ title: "Kuiz BH" });
  const kuizPageProps: BHBasePageProps = {
    gaData,
    metaTags,
    uniqueSectionName: sectionName,
    pageAdTargetValue: PageAdTargetingTypeEnum.LISTING,
  };

  return (
    <BHBasePage {...kuizPageProps}>
      <WidgetWrapper className="px-0">
        <div className="flex flex-col w-full -mt-xs lg:mt-[17px]">
          <span className="hidden lg:block text-blue-100 text-lg font-semibold mb-xs">
            Kuiz BH
          </span>
          <div className="w-full lg:pt-sm">
            <WordPuzzlePage />
          </div>
        </div>
      </WidgetWrapper>
    </BHBasePage>
  );
}
