import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type FieldWrapperProps = {
  children: ReactNode;
  className?: string;
};

export function FieldWrapper({ children, className }: FieldWrapperProps) {
  return (
    <div
      data-testid="field-wrapper"
      className={twMerge("lg:max-w-[860px] mx-auto", className)}
    >
      {children}
    </div>
  );
}
