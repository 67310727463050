import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export type KuizMetaDataProps = {
  title: string;
};

export default function KuizMetaData({
  title,
}: KuizMetaDataProps): PageMetaDataValues {
  const level2 = "kuiz bh";

  const gaData: GADataProps = {
    level2: level2,
    title: title,
    section: level2,
    contentcat: "1",
    contenttype: "listing",
  };

  const metaTags: MetaTagsProps = {
    title: "Kuiz Berita Harian - Kuiz Bahasa Melayu | Beritaharian.sg",
    description:
      "Kuiz Berita Harian - Uji pengetahuan anda dalam bahasa Melayu dengan Kuiz Berita Harian hanya di BeritaHarian.sg",
    slug: "kuiz-bh",
  };

  return {
    gaData,
    metaTags,
  };
}
