import { ReactNode } from "react";

export type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeadingProps = {
  Element?: HeadingElements;
  children: ReactNode;
  className?: string;
  testId?: string;
};
export default function Heading({
  Element = "h2",
  children,
  className,
  testId,
}: HeadingProps) {
  return (
    <Element data-testid={testId} className={className}>
      {children}
    </Element>
  );
}
