import premiumIcon from "@assets/header/menu-selected/icon/premium-new.svg";
import registeredUser from "@assets/header/menu-selected/icon/registered-user-new.svg";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import { twMerge } from "tailwind-merge";

type ContainerProp = {
  label: string;
  icon: string;
  textColor: string;
  className?: string;
};

const Container = ({ label, icon, textColor, className }: ContainerProp) => (
  <span
    data-testid={`container-${label}`}
    className={twMerge(
      `flex max-w-max h-fit gap-x-1 items-center flex-nowrap`,
      textColor,
      className,
    )}
  >
    <img data-testid={`icon-${label}`} src={icon} alt="fixme" width={16} />
    <span data-testid={label} className="font-primary text-xxs">
      {label}
    </span>
  </span>
);

export default function LabelCardIconNew({
  contentAccess,
  className,
}: {
  contentAccess?: LabelCardIconVariety;
  className?: string;
}) {
  switch (contentAccess) {
    case LabelCardIconVariety.FREE:
      return null;
    case LabelCardIconVariety.PREMIUM:
      return (
        <Container
          label="Premium"
          icon={premiumIcon}
          textColor="text-violet-200"
          className={className}
        />
      );
    case LabelCardIconVariety.REGISTERED_USER:
      return (
        <Container
          label="Pengguna Berdaftar"
          icon={registeredUser}
          textColor="text-purple-300"
          className={className}
        />
      );
    default:
      return null;
  }
}
