import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { useSubscribeLink } from "@helper/checkMobileAppLink";
import cx from "classnames";
import React from "react";

import { FooterMenuDetails } from "./footerMenuConstants";
import { FooterMenuType } from "./footerMenuType";

type FooterMenuListProps = {
  testId: string;
  items: FooterMenuDetails[];
  type: FooterMenuType;
};

export function FooterMenuList({ testId, items, type }: FooterMenuListProps) {
  const subLink = useSubscribeLink();
  return (
    <List
      testId={`${testId}-${type}-footerMenuList`}
      style={cx(
        "text-white-100",
        type === "social" && "flex gap-xs mt-xxs [&_img]:w-[40px]",
        type === "top" && "mt-sm [&_a]:thin",
        type === "eshop" && "mb-sm [&_a]:font-semibold",
        type === "appDownload" &&
          "flex mt-xs gap-xxs [&_img]:bg-white-100 [&_img]:rounded-[50%] [&_img]:w-[45px]",
      )}
    >
      {items.map((item: FooterMenuDetails, index: number) => (
        <React.Fragment key={index}>
          {!(subLink === "" && item.title === "Langgan") && (
            <>
              <ListItem
                key={`${testId}-item-${index}`}
                testId={`${testId}-item-${index}`}
              >
                <a
                  key={index}
                  href={item.url}
                  target={item.newTab ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className="text-sm font-primary text-white-100 lead-[150%] font-thin hover:underline"
                >
                  {item.title ? item.title : null}
                  {item.images ? (
                    <img src={item.images} alt={`${item.title}-menu-icon`} />
                  ) : null}
                </a>
              </ListItem>
            </>
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
