const NEWSLETTER_CONTACT_LIST_ID = "934866939";

const NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG = "Langganan anda terdapat masalah."; // "There is a problem with your subscription."
const NEWSLETTER_SIGN_UP_SUCCESS_MSG = "Langganan anda berjaya."; // Your subscription is successful.
const NEWSLETTER_SIGN_UP_INVALID_EMAIL = "Alamat email tidak sah"; // Invalid email address

export {
  NEWSLETTER_CONTACT_LIST_ID,
  NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG,
  NEWSLETTER_SIGN_UP_INVALID_EMAIL,
  NEWSLETTER_SIGN_UP_SUCCESS_MSG,
};
