import { ProcessedTextElement } from "@app/types/Opensearch";
import quote1 from "@assets/article/quote1.svg";
import quote2 from "@assets/article/quote2.svg";
import ApplyParagraphAnnotations from "@elements/ArticleParagraph/ApplyParagraphAnnotations";
import { twMerge } from "tailwind-merge";

type PullQuoteCardProps = {
  quote: ProcessedTextElement;
  quotee: ProcessedTextElement;
  quoteLabel: ProcessedTextElement | undefined;
  quoteCredit: ProcessedTextElement | undefined;
};

export default function PullQuoteCard({
  quote,
  quotee,
  quoteLabel,
  quoteCredit,
}: PullQuoteCardProps) {
  const borderBottom = "border-b-[1px] border-grey-850";
  const flexCol = "flex flex-col";
  const quoteImage = "h-[21px] md:h-auto";

  return (
    <div
      className={twMerge(
        "gap-2 lg:gap-3 my-xl pb-xs text-black-200",
        borderBottom,
        flexCol,
      )}
    >
      {quoteLabel && quoteLabel.value && (
        <div
          className={twMerge(
            "font-semibold text-md md:text-xl pb-xs mb-xs",
            borderBottom,
          )}
        >
          {ApplyParagraphAnnotations(quoteLabel.value, quoteLabel.annotations)}
        </div>
      )}
      <div
        className={twMerge(
          "gap-1 md:gap-2 font-semimedium text-md md:text-xl",
          flexCol,
        )}
      >
        <img src={quote1} alt="quote1" width={37} className={quoteImage} />
        <span className="pt-xs pl-xs md:pt-xxs md:pl-xxs leading-[22px] md:leading-[24px]">
          {ApplyParagraphAnnotations(quote.value, quote.annotations)}
        </span>
        <img
          src={quote2}
          alt="quote2"
          width={37}
          className={twMerge(quoteImage, "self-end")}
        />
      </div>
      <div
        className={twMerge(
          "text-xs md:text-sm pl-xs md:pl-xxs font-regular leading-[17px] md:leading-[19px]",
          flexCol,
        )}
      >
        <span>
          {ApplyParagraphAnnotations(quotee.value, quotee.annotations)}
        </span>
        {quoteCredit && quoteCredit.value && (
          <span>
            {ApplyParagraphAnnotations(
              quoteCredit.value,
              quoteCredit.annotations,
            )}
          </span>
        )}
      </div>
    </div>
  );
}
