import type { UserInfoType } from "@app/hooks/useUserInfo";
import {
  mySPHOpenLogout,
  mySPHOpenResendVerificationMail,
  mySPHOpenUpdatePwd,
} from "@helper/mySPH";
import cx from "classnames";
import { MouseEvent, useEffect, useState } from "react";

import styles from "./UserInfo.module.css";

export type UserInfoProps = {
  rootClassName?: string;
  userInfo: UserInfoType;
};

/**
 *
 * @param rootClassName top-level css class override
 * @param userInfo user data decoded from JWT
 * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU/edit# for API usage of window._mySPHObj
 */
export default function UserInfo({
  rootClassName,
  userInfo,
}: UserInfoProps): React.ReactElement {
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("click", () => {
      const userInfoPanelEl = document.getElementById("user-info-panel");
      if (userInfoPanelEl) {
        setIsPanelVisible(false);
      }
    });

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <div className={cx(styles.userInfo, rootClassName)}>
      {/* eslint-disable */}
      <div
        className={cx(
          styles.navRoot,
          "flex flex-row items-center justify-center",
        )}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          e.preventDefault();
          setIsPanelVisible(!isPanelVisible);
        }}
      >
        <div
          className={cx(
            styles.dropdownToggle,
            "hover:text-grey-800 text-[12px] text-grey-800 font-semibold",
          )}
        >
          <span className="w-full"> Hi, {userInfo?.loginId}</span>
        </div>
      </div>

      {userInfo ? (
        <span id="sph_user_name" className="hidden">
          {encodeURI(userInfo.loginId)}
        </span>
      ) : null}

      {isPanelVisible ? (
        <ul id="user-info-panel" className={styles.userInfoPanel}>
          <div className="opacity-30 border  border-black border-solid w-[100%] m-[auto] mb-[5px]"></div>
          <li className="font-semibold pt-[10px]">
            <a
              target="_blank"
              href={`https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
                location.href,
              )}`}
              rel="noreferrer"
            >
              Urus Akaun
            </a>{" "}
            <span>|</span>
          </li>

          {/* eslint-disable @jsx-a11y/click-events-have-key-events */}
          {userInfo?.sub === "unverified" ? (
            <li
              className="font-semibold"
              onClick={mySPHOpenResendVerificationMail}
            >
              <span className="font-poppins text-red">
                <strong>Sila sahkan e-mel anda</strong>
              </span>
            </li>
          ) : null}
          <li
            className="font-semibold pb-[20px] pt-[20px]"
            onClick={mySPHOpenUpdatePwd}
          >
            Kemas kini kata laluan anda <span>|</span>
          </li>
          <li className="font-semibold pb-[10px]" onClick={mySPHOpenLogout}>
            Log keluar
          </li>
        </ul>
      ) : null}
    </div>
  );
}
