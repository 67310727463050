import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type DateProps = {
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
  variant?: "dash" | "or" | "default";
  date?: string;
  time?: string;
  style?: string;
};

export default function Date({
  children,
  className,
  dataTestId,
  variant = "or",
  date,
  time,
}: DateProps) {
  switch (variant) {
    case "dash":
      return (
        <span
          data-testid="date"
          className={twMerge(
            "text-xss md:text-xs text-grey-400 md:pt-sm md:pb-2 self-start md:self-end text-nowrap",
            className,
          )}
        >
          <span className="font-bold uppercase">{date}</span>
          {`- ${time}`}
        </span>
      );
    case "or":
      return (
        <span
          data-testid="date"
          className={twMerge(
            "text-[12px] md:text-xs text-grey-400 text-nowrap",
            className,
          )}
        >
          {`${date} | `} <span className="uppercase">{time}</span>
        </span>
      );
    case "default":
      return (
        <span className={className} data-testid={dataTestId}>
          {children}
        </span>
      );
  }
}
