import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useCallback } from "react";

export function useKuizVirtualPVAnalyticsCall() {
  const sendDataToGTM = useGTMDispatch();

  const analyticsCall = useCallback(
    (title: string, pagination: string) => {
      sendDataToGTM({
        event: "virtual_pv",
        title: title,
        pagination: pagination,
      });
    },
    [sendDataToGTM],
  );

  return analyticsCall;
}
