import QuizButton from "./QuizButton";
import Results from "./Results";

type ResultsSectionProps = {
  isMobile: boolean;
  correctCount: number;
  totalCount: number;
  handleQuizClose: () => void;
  // isCurrentDate: () => boolean;
  // showNextPre: boolean;
  // handleQuizNavigation: (e: number) => void;
  showResult: boolean;
};

export default function ResultsSection({
  isMobile,
  correctCount,
  totalCount,
  handleQuizClose,
  // isCurrentDate,
  // showNextPre,
  // handleQuizNavigation,
  showResult = true,
}: ResultsSectionProps) {
  return !isMobile ? (
    <div className="flex items-center shadow-lg justify-between flex-row bg-white-100 min-h-[55px]">
      <div className="flex items-center flex-row bg-white-100 xs:pt-0 justify-center w-9/12">
        {/* {showNextPre && (
          <QuizButton
            handleClick={() => {
              handleQuizNavigation(-1);
            }}
            text="< Kuiz sebelumnya"
            wrapperStyle="basis-4/12"
            buttonStyle="text-blue-100 underline justify-end"
          />
        )} */}
        {showResult && (
          <Results
            correctCount={correctCount}
            totalCount={totalCount}
            wrapperStyle="basis-6/12 justify-end"
            // wrapperStyle={`basis-5/12 ${!isCurrentDate() ? "justify-end" : "justify-start"}`}
          />
        )}
        {/* {showNextPre && !isCurrentDate() && (
          <QuizButton
            handleClick={() => {
              handleQuizNavigation(1);
            }}
            text="Kuiz seterusnya >"
            buttonStyle="text-blue-100 underline justify-end"
          />
        )} */}
      </div>
      <div className="flex items-center flex-col bg-white-100 w-3/12">
        <QuizButton
          handleClick={handleQuizClose}
          text="Tutup Kuiz"
          buttonStyle="text-black-200 underline"
        />
      </div>
    </div>
  ) : (
    <div className="flex items-center shadow-lg justify-between flex-col bg-white-100">
      <div className="flex flex-row w-full justify-between">
        {showResult && (
          <>
            <Results
              correctCount={correctCount}
              totalCount={totalCount}
              wrapperStyle="pl-2 justify-start basis-8/12"
              isMobile
            />
            <div className="flex items-center flex-col justify-center bg-white-100 w-4/12">
              <QuizButton
                handleClick={handleQuizClose}
                text="Tutup Kuiz"
                buttonStyle="text-black-200 underline"
              />
            </div>
          </>
        )}
      </div>
      <div className="flex items-center flex-row bg-white-100 xs:pt-0 justify-between w-full">
        {/* {showNextPre && (
          <QuizButton
            handleClick={() => {
              handleQuizNavigation(-1);
            }}
            text="< Kuiz sebelumnya"
            wrapperStyle="basis-3/6"
            buttonStyle="text-blue-100 underline justify-start"
          />
        )}
        {showNextPre && !isCurrentDate() && (
          <QuizButton
            handleClick={() => {
              handleQuizNavigation(1);
            }}
            text="Kuiz seterusnya >"
            wrapperStyle="basis-3/6"
            buttonStyle="text-blue-100 underline justify-end"
          />
        )} */}
      </div>
    </div>
  );
}
