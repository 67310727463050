import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ArticleCarousel.css";

import {
  GalleryCarouselItems,
  GalleryCarouselTypes,
} from "@app/types/Opensearch.d";
import nextArrow from "@assets/actions/next-white-icon.svg";
import prevArrow from "@assets/actions/prev-white-icon.svg";
import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import { YOUTUBE_WATCH_URL } from "@elements/ArticleParagraph/Embeds/constants";
import YouTubeEmbedCustom from "@elements/ArticleParagraph/Embeds/YouTubeEmbedCustom";
import { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

import ArticleImage from "./ArticleImage";

export type CarouselPropType = {
  renderItems: GalleryCarouselItems;
  sectionName?: string;
};

function ArticleCarousel({ renderItems, sectionName }: CarouselPropType) {
  const [autoPlay, setAutoPlay] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const SliderComponent =
    // @ts-expect-error @typescript-eslint/no-unsafe-assignment
    typeof window === "undefined" ? Slider.default : Slider;

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleVideoClick = () => {
    setAutoPlay(false);
  };

  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return (
      <span className="article">
        <span {...others}>{children}</span>
      </span>
    );
  };

  const settings: Settings = {
    infinite: true,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoPlay,
    autoplaySpeed: 5000,
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next}>
          <img src={nextArrow} alt="next-arrow" width={20} height={20} />
        </button>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous}>
          <img src={prevArrow} alt="prev-arrow" width={20} height={20} />
        </button>
      </SlickButtonFix>
    ),
  };

  return (
    <>
      {renderItems.length === 1 ? (
        <>
          {renderItems[0].type === GalleryCarouselTypes.IMAGE && (
            <ArticleImage
              url={renderItems[0].image?.url}
              alt={renderItems[0].id}
              sectionName={sectionName}
              caption={renderItems[0].caption}
              credit={renderItems[0].credit}
            />
          )}
          {renderItems[0].type === GalleryCarouselTypes.BRIGHTCOVE_VIDEO && (
            <BrightcoveVideo videoId={renderItems[0].videoId} />
          )}
          {renderItems[0].type === GalleryCarouselTypes.YOUTUBE_VIDEO && (
            <YouTubeEmbedCustom
              url={`${YOUTUBE_WATCH_URL}${renderItems[0].youtubeId}`}
            />
          )}
        </>
      ) : (
        <div className="max-w-[825px]">
          <SliderComponent ref={sliderRef} {...settings}>
            {renderItems.map((item) => (
              <div key={item.id}>
                {item.type === GalleryCarouselTypes.IMAGE && (
                  <ArticleImage
                    index={item.id}
                    alt={item.id}
                    url={item.image?.url}
                    caption={item.caption}
                    credit={item.credit}
                  />
                )}
                {item.type === GalleryCarouselTypes.BRIGHTCOVE_VIDEO && (
                  <div
                    onClick={handleVideoClick}
                    onKeyUp={() => {}}
                    role="button"
                    key={item.videoId}
                    tabIndex={0}
                  >
                    <BrightcoveVideo videoId={item.videoId} />
                  </div>
                )}
                {item.type === GalleryCarouselTypes.YOUTUBE_VIDEO && (
                  <div
                    onClick={handleVideoClick}
                    onKeyUp={() => {}}
                    role="button"
                    key={item.youtubeId}
                    tabIndex={0}
                  >
                    <YouTubeEmbedCustom
                      url={`${YOUTUBE_WATCH_URL}${item.youtubeId}`}
                    />
                  </div>
                )}
              </div>
            ))}
          </SliderComponent>
        </div>
      )}
    </>
  );
}

export default ArticleCarousel;
