import MarkerBlue from "@assets/foodmap/marker-blue.svg";
import MarkerGray from "@assets/foodmap/marker-gray.svg";
import type { MappedMarkers } from "@typings/foodMapTypes";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

export type MarkerProps = {
  id: number;
  marker: MappedMarkers;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  selectedMarker: number;
};

export default function Marker({
  id,
  marker,
  onClick,
  selectedMarker,
}: MarkerProps) {
  return (
    <>
      <AdvancedMarker
        key={id}
        {...marker}
        className="relative"
        onClick={onClick}
      >
        <span className="absolute top-[20%] left-[30%]">{marker.label}</span>
        {selectedMarker === marker.id ? (
          <img src={MarkerBlue} width={30} height={40} alt="Blue Marker" />
        ) : (
          <img src={MarkerGray} width={30} height={40} alt="Gray Marker" />
        )}
      </AdvancedMarker>
    </>
  );
}
