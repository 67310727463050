import foodmap1 from "@assets/foodmap/bhsosedap-1.avif";
import foodmap2 from "@assets/foodmap/bhsosedap-2.avif";
import foodmap3 from "@assets/foodmap/bhsosedap-3.avif";
import foodMapDesktopBodyLogo from "@assets/foodmap/foodmap-desktop-body-logo.svg";
import qrCode from "@assets/foodmap/sosedap-qr.avif";

import FoodMapHeader from "./FoodMapHeader";

export default function FoodMapDesktop() {
  return (
    <div>
      <FoodMapHeader BHLogo={true} FoodMapLink={false} />
      <div
        data-testid="foodmap-desktop"
        className="hidden lg:flex p-xl bg-white-200 gap-2"
      >
        <div className="flex items-start w-5/12 text-black-300 font-regular gap-1 min-w-[720px]">
          <img
            src={foodMapDesktopBodyLogo}
            alt="desktop-log"
            width="71px"
            height="102px"
          />
          <span className="text-xxs min-w-[60px] w-1/12">
            What is the Berita Harian Halal Food Map?
          </span>
          <p className="text-xs w-5/12 leading-[18px]">
            No matter where you are in Singapore, the Halal Food Map shows you
            halal eateries nearest to you, in real time, on your smartphone.
            <br />
            Just a single tap. No typing is needed. It is that easy. Click on
            the eateries listed and you are all set to enjoy some of the
            tastiest halal dishes in town!
            <br />
            You might just discover hidden gems to add to your list of favorite
            places to get your cravings fixed.
          </p>
          <div className="flex w-7/12 gap-md">
            <img
              src={qrCode}
              alt="scan-qr"
              width="150px"
              height="150px"
              className="w-6/12 max-w-[150px] max-h-[150px]"
            />
            <p className="font-secondary text-xl leading-[28px]">
              Scan this QR code with your smartphone to view the map.
            </p>
          </div>
        </div>
        <div className="w-7/12">
          <img src={foodmap1} alt="foodmap-img-1" />
          <img src={foodmap2} alt="foodmap-img-2" />
          <img src={foodmap3} alt="foodmap-img-3" />
        </div>
      </div>
    </div>
  );
}
