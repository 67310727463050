/**
 * Company: SPHMedia
 * Description: [Translated Article Modal]
 */

import TranslateModalClose from "@assets/article/translateModalClose.svg";
import { StandFirst } from "@components/Elements/Typography/Paragraph";
import { FieldWrapper } from "@components/Translation/Elements/FieldWrapper";
import TranslationImage from "@components/Translation/Elements/TranslationImage";
import Heading from "@elements/Typography/Heading";
import { ReactElement } from "react";

export type TranslatedArticleModalProps = {
  id: string;
  title: string;
  sectionName: string;
  standFirst?: string;
  body: string[];
  imageUrl?: string;
  isOpen: boolean;
  onClose: () => void;
};

/**
 * TranslatedArticleModal
 * Description: [Opens translated content of article as a modal]
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function TranslatedArticleModal(
  props: TranslatedArticleModalProps,
): ReactElement {
  const { title, sectionName, standFirst, body, imageUrl, onClose } = props;

  return (
    <div className="flex flex-col gap-xs items-center mb-md">
      <FieldWrapper>
        <div className="flex flex-col gap-xs relative">
          <button className="absolute right-0" onClick={onClose}>
            <img
              src={TranslateModalClose}
              alt="translate-close"
              width={23}
              height={23}
            />
          </button>
          <TranslationImage sectionName={sectionName} alt={""} url={imageUrl} />
          <div className="flex flex-col gap-xs px-sm lg:px-0">
            <span className="text-blue-400 text-sm font-primary font-semimedium">
              Translation by Google Translate
            </span>
            <Heading
              Element="h2"
              className="font-secondary leading-2xl text-black-800 text-xl lg:text-[38px] font-medium"
            >
              {title}
            </Heading>
            {standFirst && (
              <StandFirst className="font-medium text-black-800 lg:text-[20px] my-3 lg:my-4">
                {standFirst}
              </StandFirst>
            )}
          </div>
        </div>
      </FieldWrapper>
      <div className="flex flex-col gap-xs">
        {body.map((para, index) => (
          <FieldWrapper key={index}>
            <p className="text-sm font-primary font-semimedium text-black-800 leading-[24px] px-sm lg:px-0">
              {para}
            </p>
          </FieldWrapper>
        ))}
      </div>
    </div>
  );
}
