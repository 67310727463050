/**
 * Check if the page is opened in Facebook or Instagram in-app browser.
 *
 * This function checks the `navigator.userAgent` string to determine if the page
 * is being viewed within the in-app browser of Facebook or Instagram.
 *
 * Example User Agents:
 * - Facebook iOS:
 *   Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
 *   [FBAN/FBIOS;FBAV/202.0.0.45.96;FBBV/15126194;FBDV/iPhone8,1;FBMD/iPhone;FBSN/iOS;FBSV/14.0;FBSS/2;FBCR/;FBID/phone;FBLC/en_US;FBOP/5]
 * - Facebook Android:
 *   Mozilla/5.0 (Linux; Android 10; SM-G973F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.136 Mobile Safari/537.36
 *   [FB_IAB/FB4A;FBAV/251.0.0.44.114;]
 * - Instagram iOS:
 *   Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
 *   Instagram 155.0.0.37.107 (iPhone8,1; iOS 14_0; en_US; en-US; scale=2.00; 750x1334; 242190700)
 * - Instagram Android:
 *   Mozilla/5.0 (Linux; Android 10; SM-G973F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.136 Mobile Safari/537.36
 *   Instagram 151.0.0.33.120 Android (29/10; 420dpi; 1080x2132; samsung; SM-G973F; beyond1; exynos9820; en_US; 229708240)
 *
 * @returns {boolean} True if the page is opened in Facebook or Instagram in-app browser, otherwise false.
 */
export default function checkInAppBrowser(): boolean {
  const userAgent = navigator.userAgent;
  return (
    userAgent.includes("FBAN") ||
    userAgent.includes("FBAV") ||
    userAgent.includes("Instagram")
  );
}
