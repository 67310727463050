import { CompanionAdsBottomSpecial } from "@elements/Advertisement/variant/CompanionAdsBottomSpecial";
import Link from "@elements/Typography/Link";
import debounce from "@helper/debounce";
import fetchBHApi from "@helper/fetchBHApi";
import { isClientSide } from "@helper/utils";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import { useCallback, useEffect, useState } from "react";

interface TranslationState {
  isVisible: boolean;
  selectedSource: string;
  translanationResult: string;
  selectedSourceDomPostion: {
    top: number;
    left: number;
  };
}

const defaultTranslationStateValues: TranslationState = {
  isVisible: false,
  selectedSource: "",
  translanationResult: "",
  selectedSourceDomPostion: {
    top: 0,
    left: 0,
  },
};

export default function TranslationPopover({
  gsChannels,
  articleSlotTarget,
}: {
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
}): JSX.Element {
  const [translationPopoverState, setTranslationPopoverState] =
    useState<TranslationState>(defaultTranslationStateValues);
  const [adNum, setAdNum] = useState<number>(0);

  const analyticsCall = useBHCustomEventAnalyticsCall();
  const pageAdTarget = PageAdTargetingTypeEnum.ARTICLE;

  const handleSelect = useCallback(async () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString() ?? "";

    if (selection && selectedText.length > 2) {
      const rect = selection.getRangeAt(0).getBoundingClientRect();

      const top = rect.top + window.scrollY + rect.height + 10;
      const left =
        rect.left +
        window.scrollX -
        (window.innerWidth > 1300 && rect.width < 350
          ? (window.innerWidth * 30) / 100 - 310
          : 0);

      setTranslationPopoverState({
        ...defaultTranslationStateValues,
        isVisible: true,
        selectedSource: selectedText,
        translanationResult: "", // Set this later after fetching translation
        selectedSourceDomPostion: { top, left },
      });

      try {
        const res = await fetchBHApi("article/translate", "POST", {
          text: selectedText,
        });
        let response = "Translation not available, please try again!";
        if (res) {
          response = res as string;
        }

        setTranslationPopoverState((prevState) => {
          return {
            ...prevState,
            selectedSourceDomPostion: { top, left },
            translanationResult: response,
          };
        });

        analyticsCall("Translation Tool", "SectionSelect", "Translate", false);
        setAdNum((prev) => prev + 1);
      } catch (error) {
        console.error("Error translating text:", error);
        setTranslationPopoverState(defaultTranslationStateValues);
      }
    } else {
      setTranslationPopoverState(defaultTranslationStateValues);
    }
  }, [analyticsCall]);

  const debounceHandleSelect = debounce(() => {
    void handleSelect();
  }, 800);

  const handleOnClickPopup = () => {
    window.getSelection()?.removeAllRanges();
    setTranslationPopoverState(defaultTranslationStateValues);
  };

  useEffect(() => {
    if (isClientSide) {
      document
        .getElementById("popupClick")
        ?.addEventListener("click", handleOnClickPopup);
      document.addEventListener("selectionchange", debounceHandleSelect);
      document.addEventListener("touchend", debounceHandleSelect);

      return () => {
        document.removeEventListener("selectionchange", debounceHandleSelect);
        document.removeEventListener("touchend", debounceHandleSelect);
        document
          .getElementById("popupClick")
          ?.removeEventListener("click", handleOnClickPopup);
      };
    }
  }, [debounceHandleSelect]);

  return (
    <>
      {translationPopoverState.isVisible && (
        <div
          className="block absolute z-50 border border-solid min-w-72 border-blue-850 bg-white-100 max-w-[322px] text-start break-words rounded"
          style={{
            top: `${translationPopoverState.selectedSourceDomPostion.top}px`,
            left: `${translationPopoverState.selectedSourceDomPostion.left}px`,
          }}
        >
          <div className="relative bottom-5 left-12 w-12 h-5 overflow-hidden inline-block">
            <div className="h-5 w-8 bg-white-100 border border-blue-850 rotate-[65deg] transform origin-bottom-left skew-x-[36deg]"></div>
          </div>
          <div className="p-4 pt-0 text-black-300 mt-[-10px]">
            <div id="popupClick">
              <div className="font-primary font-bold text-md text-black-100 leading-xs pb-2">
                MALAY
              </div>
              <div className="font-primary font-bold text-xs text-black-100 leading-[17px]">
                {translationPopoverState.selectedSource}
              </div>
              <br />
              <div className="font-primary font-bold text-md text-black-100 leading-xs pb-2">
                ENGLISH
              </div>
              <div className="font-primary font-bold text-xs text-black-100 leading-[17px]">
                {translationPopoverState.translanationResult}
              </div>
            </div>
            <br />
            <Link
              target="_blank"
              relativePath={false}
              className="font-primary font-bold text-md leading-xs text-blue-300"
              destination={`https://translate.google.com/?source=gtx_m#ms/en/' + ${translationPopoverState.selectedSource}`}
            >
              MORE
            </Link>
          </div>
          <CompanionAdsBottomSpecial
            pageAdTargetType={pageAdTarget}
            index={adNum}
            gsChannels={gsChannels}
            articleSlotTarget={articleSlotTarget}
          />
        </div>
      )}
    </>
  );
}
