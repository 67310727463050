import type { UserInfoType } from "@app/hooks/useUserInfo";
import iconProfile from "@assets/header/icon-profile-new.svg";
import slideDownIcon from "@assets/header/slideDownIcon.svg";
import slideDownIconMobile from "@assets/header/slideDownIconMobile.svg";
import slideUpIconMobile from "@assets/header/slideUpIconMobile.svg";
import {
  mySPHOpenLogout,
  mySPHOpenResendVerificationMail,
  mySPHOpenUpdatePwd,
} from "@helper/mySPH";
import cx from "classnames";
import { useEffect, useState } from "react";

export type UserInfoProps = {
  rootClassName?: string;
  userInfo: UserInfoType;
};

/**
 *
 * @param rootClassName top-level css class override
 * @param userInfo user data decoded from JWT
 * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU/edit# for API usage of window._mySPHObj
 */
export default function UserInfo({
  rootClassName,
  userInfo,
}: UserInfoProps): React.ReactElement {
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("click", () => {
      const userInfoPanelEl = document.getElementById("user-info-panel");
      if (userInfoPanelEl) {
        setIsPanelVisible(false);
      }
    });

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <div className={cx(rootClassName)}>
      <button
        className={`flex w-full items-center justify-center h-[54px] lg:h-auto
          ${isPanelVisible ? "bg-grey-350 lg:bg-white-100" : ""}
          `}
        onClick={(e) => {
          e.stopPropagation();
          setIsPanelVisible(!isPanelVisible);
        }}
      >
        <div className="w-full h-[54px] lg:h-auto px-sm lg:px-0 lg:w-auto flex items-center gap-1 text-sm text-white-500 font-semimedium lg:text-[12px] lg:text-blue-400 lg:font-regular justify-between">
          <img
            className="hidden lg:block"
            src={iconProfile}
            alt="profile-icon"
            width={12}
            height={12}
          />
          <span className="w-full flex-grow lg:flex-grow-0 text-left leading-[24px]">
            Hi, {userInfo?.loginId}
          </span>
          <img
            className={`hidden lg:block ${isPanelVisible && "rotate-180"}`}
            src={slideDownIcon}
            alt="slide-down-icon"
            width={12}
            height={12}
          />
          {!isPanelVisible && (
            <img
              className="lg:hidden"
              src={slideDownIconMobile}
              alt="slide-down-icon-mobile"
              width={14}
              height={14}
            />
          )}
          {isPanelVisible && (
            <img
              className="lg:hidden"
              src={slideUpIconMobile}
              alt="slide-up-icon-mobile"
              width={14}
              height={14}
            />
          )}
        </div>
      </button>

      {userInfo ? (
        <span id="sph_user_name" className="hidden">
          {encodeURI(userInfo.loginId)}
        </span>
      ) : null}

      <ul
        id="user-info-panel"
        className={`flex flex-col z-10 min-w-[187px] gap-sm lg:gap-xs lg:absolute bg-grey-350 lg:bg-grey-1000 top-[24px] right-0 text-white-500 lg:text-grey-350 text-sm lg:text-xxs leading-[22px] lg:leading-[18px] transition-all duration-500 ease-in-out p-lg py-xs lg:p-xs
          ${isPanelVisible ? "opacity-100" : "opacity-0 hidden"}`}
      >
        <li>
          <a
            target="_blank"
            href={`https://subscribe.sph.com.sg/your-account/?utm_campaign=manageaccount&utm_medium=sph-publication&utm_source=bt&utm_content=manageaccountlink-dropdown&pub-code=bt${encodeURIComponent(
              location.href,
            )}`}
            rel="noreferrer"
            className="py-xxs lg:py-0"
          >
            Urus Akaun
          </a>
          {userInfo?.sub === "unverified" ? (
            <li>
              <button onClick={mySPHOpenResendVerificationMail}>
                <span className="text-grey-150 lg:text-black-700">
                  Sila sahkan e-mel anda
                </span>
              </button>
            </li>
          ) : null}
        </li>
        <li>
          <button className="py-xxs lg:py-0" onClick={mySPHOpenUpdatePwd}>
            Kemas kini kata laluan anda
          </button>
        </li>
        <li>
          <button className="py-xxs lg:py-0" onClick={mySPHOpenLogout}>
            Log keluar
          </button>
        </li>
      </ul>
    </div>
  );
}
