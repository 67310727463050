import { twMerge } from "tailwind-merge";

type QuizButtonProps = {
  handleClick: () => void;
  wrapperStyle?: string;
  buttonStyle?: string;
};

export default function QuizButton({
  handleClick,
  wrapperStyle = "",
  buttonStyle = "",
}: QuizButtonProps) {
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full mb-md",
        wrapperStyle,
      )}
    >
      <button
        className={twMerge(
          "flex items-center justify-center bg-blue-400 text-white-200 text-[17px] lg:text-md font-semibold text-center w-[200px] lg:w-[300px] h-[40px] lg:h-[48px] rounded-[30px]",
          buttonStyle,
        )}
        onClick={handleClick}
      >
        Cuba kuiz baru
      </button>
    </div>
  );
}
