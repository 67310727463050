export const URL_ORIGIN = "https://www.beritaharian.sg/";
export const OG_SITE_NAME = "BeritaHarian";
export const TWITTER_CARD = "summary_large_image";
export const ROBOTS =
  "index, follow, notranslate, max-image-preview:large, max-snippet:-1, max-video-preview:-1";

export const FB_PAGES = "489801731109245";
export const FB_APP_ID = "250073846011154";

export const IOS_APP_STORE_ID = "743558902";
export const ITUNES_APP = "app-id=743558902";
export const APP_NAME = "Berita Harian";

export const ANDROID_PACKAGE = "com.sph.bhandroid";
