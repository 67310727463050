import dollarIcon from "@assets/header/menu-selected/icon/free.svg";
import premiumIcon from "@assets/header/menu-selected/icon/premium.svg";
import registeredUser from "@assets/header/menu-selected/icon/registered-user.svg";
import { twMerge } from "tailwind-merge";

type ContainerProp = {
  label: string;
  icon: string;
  backgroundColor: string;
  className?: string;
};

export enum LabelCardIconVariety {
  FREE = "0",
  PREMIUM = "1",
  REGISTERED_USER = "2",
}

const Container = ({
  label,
  icon,
  backgroundColor,
  className,
}: ContainerProp) => (
  <span
    data-testid={`container-${label}`}
    className={twMerge(
      `flex max-w-max h-fit p-1 rounded-2xl gap-x-1 items-center text-white-100 flex-nowrap`,
      backgroundColor,
      className,
    )}
  >
    <img data-testid={`icon-${label}`} src={icon} alt="fixme" width={17} />
    <span data-testid={label} className="font-primary text-xxs">
      {label}
    </span>
  </span>
);

export default function LabelCardIcon({
  contentAccess,
  className,
}: {
  contentAccess?: LabelCardIconVariety;
  className?: string;
}) {
  switch (contentAccess) {
    case LabelCardIconVariety.FREE:
      return (
        <Container
          label="Akses Percuma"
          icon={dollarIcon}
          backgroundColor="bg-blue-400"
          className={className}
        />
      );
    case LabelCardIconVariety.PREMIUM:
      return (
        <Container
          label="Premium"
          icon={premiumIcon}
          backgroundColor="bg-purple-100"
          className={className}
        />
      );
    case LabelCardIconVariety.REGISTERED_USER:
      return (
        <Container
          label="Pengguna Berdaftar"
          icon={registeredUser}
          backgroundColor="bg-purple-200"
          className={className}
        />
      );
    default:
      return null;
  }
}
