import Audio from "@components/Audio/Audio";
import Date from "@elements/Typography/Date";

export type AudioPlayerProps = {
  src: string;
  height?: string;
  title?: string;
  published_at?: string;
  name?: string;
};

export default function AudioPlayer({
  src,
  published_at,
  name,
  height,
}: AudioPlayerProps) {
  return (
    <div className="flex flex-col">
      <h1 className="text-xl lg:text-4xl text-black-200 leading-2xl font-secondary font-bold pb-6">
        {name}
      </h1>
      <Date
        className="font-secondary text-grey-400 text-xs self-start lg:items-center pb-2"
        dataTestId="date"
        variant="default"
      >
        {published_at}
      </Date>
      <Audio src={`${src}?autoplay=1`} height={height} />
    </div>
  );
}
