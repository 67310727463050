import bahasaIcon from "@assets/header/menu/icon/bahasa.svg";
import bahasaSelected from "@assets/header/menu/icon/bahasa-selected.svg";
import dollarIcon from "@assets/header/menu/icon/dollar.svg";
import duniaIcon from "@assets/header/menu/icon/dunia.svg";
import ekoniagaIcon from "@assets/header/menu/icon/ekoniaga.svg";
import ghIcon from "@assets/header/menu/icon/gaya-hidup.svg";
import gengIcon from "@assets/header/menu/icon/gen-g.svg";
import gengIconSelected from "@assets/header/menu/icon/gen-g-white.svg";
import malaysiaIcon from "@assets/header/menu/icon/malaysia.svg";
import podcastIcon from "@assets/header/menu/icon/podcast.svg";
import podcastIconSelected from "@assets/header/menu/icon/podcast-white.svg";
import premiumIcon from "@assets/header/menu/icon/premium.svg";
import singapuraIcon from "@assets/header/menu/icon/singapura.svg";
import sukanIcon from "@assets/header/menu/icon/sukan.svg";
import userIcon from "@assets/header/menu/icon/user.svg";
import videoIcon from "@assets/header/menu/icon/video.svg";
import videoIconSelected from "@assets/header/menu/icon/video-white.svg";
import wacanaIcon from "@assets/header/menu/icon/wacana.svg";
import ghIconSelected from "@assets/header/menu-selected/icon/gaya-hidup.svg";
import malaysiaIconSelected from "@assets/header/menu-selected/icon/malaysia.svg";
import singapuraIconSelected from "@assets/header/menu-selected/icon/singapura.svg";
import { EPAPER_LINK } from "@helper/getEnvVariables";

export type SectionDetails = Readonly<{
  title: string;
  url: string;
  images?: string | undefined;
  newTab?: boolean | undefined;
  selectedColor?: string | undefined;
  selectedIcon?: string | undefined;
  neutralColor?: boolean | undefined;
}>;

export const SECTION_LIST_NEW: SectionDetails[] = [
  {
    title: "Singapura",
    url: "/singapura",
    images: singapuraIcon,
    selectedColor: "red-100",
    selectedIcon: singapuraIconSelected,
  },
  {
    title: "Malaysia",
    url: "/malaysia",
    images: malaysiaIcon,
    selectedColor: "pink-600",
    selectedIcon: malaysiaIconSelected,
  },
  {
    title: "Gaya Hidup",
    url: "/gaya-hidup",
    images: ghIcon,
    selectedColor: "red-600",
    selectedIcon: ghIconSelected,
  },
  {
    title: "Dunia",
    url: "/dunia",
    selectedColor: "pink-600",
    images: duniaIcon,
    selectedIcon: duniaIcon,
  },
  {
    title: "Kuiz BH",
    url: "/kuiz-bh",
  },
  {
    title: "Webinar",
    url: "/webinar",
  },
  {
    title: "mata-mata",
    url: "/mata-mata?ref=menu",
  },
  {
    title: "Gen G",
    url: "/gen-g",
    images: gengIcon,
    selectedColor: "blue-150",
    selectedIcon: gengIconSelected,
  },
  {
    title: "Pengguna Berdaftar",
    url: "/registered",
    selectedColor: "purple-300",
    images: userIcon,
    selectedIcon: userIcon,
  },
  {
    title: "Podcast",
    url: "/podcasts",
    images: podcastIcon,
    selectedIcon: podcastIconSelected,
    selectedColor: "blue-400",
    neutralColor: true,
  },
  {
    title: "Videos",
    url: "/videos",
    images: videoIcon,
    selectedIcon: videoIconSelected,
    selectedColor: "blue-400",
    neutralColor: true,
  },
];

export const SECONDARY_SECTION_LIST_NEW: SectionDetails[] = [
  {
    title: "Akses Percuma",
    url: "/akses-percuma",
    selectedColor: "blue-400",
    images: dollarIcon,
    selectedIcon: dollarIcon,
  },
  {
    title: "Premium",
    url: "/premium-articles",
    selectedColor: "violet-200",
    images: premiumIcon,
    selectedIcon: premiumIcon,
  },
  {
    title: "Ekonomi",
    url: "/ekonomi-kerja",
    selectedColor: "green-400",
    images: ekoniagaIcon,
    selectedIcon: ekoniagaIcon,
  },
  {
    title: "Wacana",
    url: "/wacana",
    selectedColor: "violet-300",
    images: wacanaIcon,
    selectedIcon: wacanaIcon,
  },
  {
    title: "Sukan",
    url: "/sukan",
    selectedColor: "orange-300",
    images: sukanIcon,
    selectedIcon: sukanIcon,
  },
  {
    title: "Bahasa & Budaya",
    url: "/bahasa-budaya",
    selectedColor: "pink-500",
    images: bahasaIcon,
    selectedIcon: bahasaSelected,
  },
  {
    title: "BH Services",
    url: "/bh-services",
  },
];

export const MISC_LIST: SectionDetails[] = [
  {
    title: "eNewsletter",
    url: "/ringkasan-berita-harian",
  },
  {
    title: "ePaper",
    url: EPAPER_LINK,
    newTab: true,
  },
];

export const MOBILE_MAIN_LIST: SectionDetails[] = [
  {
    title: "Kuiz BH",
    url: "/kuiz-bh",
  },
  {
    title: "mata-mata",
    url: "/mata-mata?ref=menu",
  },
  {
    title: "Webinar",
    url: "/webinar",
  },
  {
    title: "BH Services",
    url: "/bh-services",
  },
];

export const MOBILE_SECTION_LIST: SectionDetails[] = [
  {
    title: "Singapura",
    url: "/singapura",
    images: singapuraIcon,
    selectedColor: "red-100",
    selectedIcon: singapuraIcon,
  },
  {
    title: "Malaysia",
    url: "/malaysia",
    images: malaysiaIcon,
    selectedColor: "pink-600",
    selectedIcon: malaysiaIcon,
  },
  {
    title: "Dunia",
    url: "/dunia",
  },
  {
    title: "Ekonomi",
    url: "/ekonomi-kerja",
  },
  {
    title: "Gaya Hidup",
    url: "/gaya-hidup",
    images: ghIcon,
    selectedColor: "red-600",
    selectedIcon: ghIcon,
  },
  {
    title: "Wacana",
    url: "/wacana",
  },
  {
    title: "Sukan",
    url: "/sukan",
  },
  {
    title: "Bahasa & Budaya",
    url: "/bahasa-budaya",
    selectedColor: "pink-500",
    images: bahasaIcon,
    selectedIcon: bahasaSelected,
  },
  {
    title: "Podcast",
    url: "/podcasts",
    images: podcastIcon,
    selectedColor: "blue-400",
  },
  {
    title: "Videos",
    url: "/videos",
    images: videoIcon,
    selectedColor: "blue-400",
  },
  {
    title: "Gen-G",
    url: "/gen-g",
  },
];

export const MOBILE_MISC_LIST: SectionDetails[] = [
  {
    title: "eNewsletter",
    url: "/ringkasan-berita-harian",
  },
  {
    title: "ePaper",
    url: EPAPER_LINK,
    newTab: true,
  },
];

export const MOBILE_SECONDARY_LISTING: SectionDetails[] = [
  {
    title: "Akses Percuma",
    url: "/akses-percuma",
  },
  {
    title: "Premium",
    url: "/premium-articles",
  },
  {
    title: "Pengguna Berdaftar",
    url: "/registered",
  },
];

export const NON_HEADER_NEW: SectionDetails[] = [
  {
    title: "Rencana",
    url: "/rencana",
    selectedColor: "red-200",
  },
  {
    title: "Berita Utama",
    url: "/berita-utama",
    selectedColor: "red-100",
  },
  {
    title: "Kisah Zakat",
    url: "/kisah-zakat",
    selectedColor: "red-100",
  },
  {
    title: "Kesihatan",
    url: "/kesihatan",
    selectedColor: "red-100",
  },
];
