import bhBackLogo from "@assets/foodmap/bh-back-logo.svg";
import foodMapLogo from "@assets/foodmap/foodmap-header-logo.svg";
import halalLogo from "@assets/header/halal-logo.svg";
import Link from "@elements/Typography/Link";
import { twMerge } from "tailwind-merge";

export type FoodMapHeaderProps = {
  BHLogo: boolean;
  FoodMapLink: boolean;
  wrapperClass?: string;
  newMasthead?: boolean;
};

export default function FoodMapHeader({
  BHLogo,
  FoodMapLink,
  wrapperClass,
  newMasthead = false,
}: FoodMapHeaderProps) {
  return (
    <>
      {newMasthead ? (
        <Link destination="foodmap" className="pr-xxs">
          <img src={halalLogo} alt="halal-logo" width={73} height={24} />
        </Link>
      ) : (
        <div
          data-testid="foodmap-header"
          className={twMerge(
            "flex items-center justify-between min-h-[44px] px-sm py-[8px] lg:px-xl lg:py-0",
            wrapperClass,
          )}
        >
          {BHLogo && (
            <>
              <div className="flex items-center gap-1">
                <img
                  src={foodMapLogo}
                  alt="halal-foodmap-logo"
                  width="32px"
                  height="32px"
                />
                <span className="text-black-300 text-md lg:text-[12.8px] font-regular">
                  Halal Food Map
                </span>
              </div>
              <Link className="block hover:opacity-75" destination="">
                <img
                  src={bhBackLogo}
                  alt="bh-back-logo"
                  width="54px"
                  height="44px"
                />
              </Link>
            </>
          )}
          {FoodMapLink && (
            <>
              <div className="flex items-center gap-1">
                <img
                  src={foodMapLogo}
                  alt="halal-foodmap-logo"
                  width="26px"
                  height="26px"
                />
                <span className="text-black-300 text-[12.8px] font-regular">
                  Halal Food Map
                </span>
              </div>
              <Link
                className="text-[12.8px] text-white-100 bg-blue-300 px-[12px] py-[8px] border-[1px] border-blue-300 rounded-[4px]"
                destination="foodmap"
              >
                Cari makanan halal dekat anda
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
