import isLiveEnvironment from "@web2/web2-helpers/isLiveEnvironment.ts";
import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { MODE } from "./app/helper/getEnvVariables";
import { reactRouterRoutes } from "./app/routes";
//import { Logger } from "./logger/Logger";

function init(): void {
  const root = document.getElementById("app");
  if (!root) {
    throw new Error("Missing #root element for hydration");
  }

  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    isLiveEnvironment(MODE) &&
    window.__staticRouterHydrationData == null
  ) {
    console.error(
      "Missing hydration context window.__staticRouterHydrationData for Live " +
        "builds.",
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (!isLiveEnvironment(MODE)) {
    // Logger.init({
    //   environment: MODE,
    //   dsn: SENTRY_DSN,
    //   // Reduce in production!
    //   tracesSampleRate: 1.0,
    //   // This sets the sample rate at 10%. You may want to change it to 100%
    //   // while in development and then sample at a lower rate in production.
    //   replaysSessionSampleRate: 0.1,
    //   // If you're not already sampling the entire session, change the sample
    //   // rate to 100% when sampling sessions where errors occur.
    //   replaysOnErrorSampleRate: 1.0,
    // });
  }

  const router = createBrowserRouter(reactRouterRoutes);
  ReactDOM.hydrateRoot(
    root,
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
} else {
  init();
}
