import { SearchButton } from "./SearchButton";
import { SearchInput } from "./SearchInput";

type SearcProps = {
  expanded: boolean;
  onClick: () => void;
  isMobile?: boolean;
};

export function Search({ expanded, onClick, isMobile }: SearcProps) {
  return (
    <>
      <SearchButton expanded={expanded} onClick={onClick} isMobile={isMobile} />
      <SearchInput expanded={expanded} isMobile={isMobile} />
    </>
  );
}
