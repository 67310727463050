import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";
import {
  FilterArticlesBasedOn,
  HomeWidgetType,
  StoryPackageConfig,
} from "@typings/StoryPackage.d";

export const homeWidgetLayout: StoryPackageConfig[] = [
  {
    type: HomeWidgetType.VERTICAL,
    title: "Singapura",
    filterArticlesBasedOn: FilterArticlesBasedOn.SECTION,
    filterText: "singapura",
    moreLink: {
      destination: "singapura",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "Malaysia",
    filterArticlesBasedOn: FilterArticlesBasedOn.SECTION,
    filterText: "malaysia",
    moreLink: {
      destination: "malaysia",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "Kaki Jalan",
    filterArticlesBasedOn: FilterArticlesBasedOn.TAGS,
    filterText: "kaki-jalan",
    moreLink: {
      destination: "tags/kaki-jalan",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "Kaki Makan",
    filterArticlesBasedOn: FilterArticlesBasedOn.TAGS,
    filterText: "kaki-makan",
    moreLink: {
      destination: "tags/kaki-makan",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "DNA Dunia Anak",
    filterArticlesBasedOn: FilterArticlesBasedOn.TAGS,
    filterText: "dna-dunia-anak",
    moreLink: {
      destination: "tags/dna-dunia-anak",
    },
  },
  {
    type: HomeWidgetType.VERTICAL,
    title: "Gaya Hidup",
    filterArticlesBasedOn: FilterArticlesBasedOn.SECTION,
    filterText: "gaya-hidup",
    moreLink: {
      destination: "gaya-hidup",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "Pengguna Berdaftar",
    filterArticlesBasedOn: FilterArticlesBasedOn.PAYWALL,
    filterText: LabelCardIconVariety.REGISTERED_USER,
    moreLink: {
      destination: "registered",
    },
  },
  {
    type: HomeWidgetType.HORIZONTAL,
    title: "Dunia",
    filterArticlesBasedOn: FilterArticlesBasedOn.SECTION,
    filterText: "dunia",
    moreLink: {
      destination: "dunia",
    },
  },
];
