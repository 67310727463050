import Link from "@elements/Typography/Link";
import checkPlatformIsiOS from "@helper/checkPlatformIsiOS";
import { PositionError } from "@typings/foodMapTypes";

export default function FoodMapLocationPermission({
  error,
}: {
  error: GeolocationPositionError | PositionError;
}) {
  let message: string;
  let enableButtton: boolean = false;
  if (error.code == 1) {
    message =
      "Please enable the Location Service under 'Settings' > 'Privacy' > 'Location Services' & reload this page.";
    if (checkPlatformIsiOS()) {
      enableButtton = true;
    }
  } else if (error.code == 2) {
    message =
      'Location Service disabled. Please enable the Location Service and tap on "Allow" on prompt.';
  } else if (error.code == 404) {
    message =
      "You are accessing the foodmap outside Singapore. Click on the BH Icon to go back to home page";
  } else {
    message = "Please try again after some time.";
  }
  return (
    <div className="flex flex-col px-xl py-md text-center text-xxs text-black-300">
      {message}
      {enableButtton && (
        <div className="flex mt-md justify-center">
          <Link
            destination="App-prefs://prefs:root=Settings"
            relativePath={false}
            className="bg-blue-400 text-white-100 p-[6px] border-[1px] border-white-100 rounded-[4px]"
          >
            Enable your location service
          </Link>
        </div>
      )}
    </div>
  );
}
