import sarchExpandedLogo from "@assets/search-expanded.svg";
import sarchLogo from "@assets/search-new.svg";
import styles from "@components/HeaderNew/Search/Search.module.css";
import cx from "classnames";

type SearchButtonProps = {
  expanded: boolean;
  onClick: () => void;
  isMobile?: boolean;
};

export function SearchButton({
  expanded,
  onClick,
  isMobile,
}: SearchButtonProps) {
  return (
    <>
      {expanded ? (
        <button
          data-testid="searchClose"
          className={cx(styles.searchCloseButton, {
            [styles.isMobile]: isMobile,
          })}
          onClick={onClick}
        >
          <img
            src={sarchExpandedLogo}
            alt="search-expanded"
            width={24}
            height={24}
          />
        </button>
      ) : (
        <button
          data-testid="searchButton"
          className={cx(styles.searchButton, { [styles.isMobile]: isMobile })}
          onClick={onClick}
        >
          <img src={sarchLogo} alt="search-logo" width="24" height="24" />
        </button>
      )}
    </>
  );
}
