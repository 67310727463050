import { Image } from "@elements/ResponsiveImage/Image";
import getFallbackSectionImage from "@helper/getFallbackSectionImage";
import { twMerge } from "tailwind-merge";

type TranslationImageProps = {
  alt: string;
  url?: string;
  width?: number;
  height?: number;
  sectionName?: string;
  index?: string;
  className?: string;
};

export default function TranslationImage({
  alt,
  url,
  width = 860,
  height = 645,
  sectionName,
  index,
  className,
}: TranslationImageProps) {
  const dummyImage = getFallbackSectionImage(sectionName);
  return (
    <div className={twMerge("max-w-[860px] mb-4", className)} key={index}>
      <div className="w-full">
        <Image
          src={url ?? dummyImage}
          displayWidth={width}
          srcWidth={width}
          srcHeight={height}
          alt={alt}
        />
      </div>
    </div>
  );
}
