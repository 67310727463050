import DataWrapperEmbed from "@elements/ArticleParagraph/Embeds/DataWrapperEmbed";
import FacebookEmbedCustom from "@elements/ArticleParagraph/Embeds/FacebookEmbedCustom";
import InstagramEmbedCustom from "@elements/ArticleParagraph/Embeds/InstagramEmbedCustom";
import TiktokEmbedCustom from "@elements/ArticleParagraph/Embeds/TiktokEmbedCustom";
import XEmbedCustom from "@elements/ArticleParagraph/Embeds/XEmbedCustom";
import YouTubeEmbedCustom from "@elements/ArticleParagraph/Embeds/YouTubeEmbedCustom";
import { isThisUrlFrom } from "@helper/utils";

export default function SocialEmbedFactory(source: string) {
  if (isThisUrlFrom(source, "tiktok.com")) return TiktokEmbedCustom;

  if (isThisUrlFrom(source, "youtube.com")) return YouTubeEmbedCustom;
  if (isThisUrlFrom(source, "youtu.be")) return YouTubeEmbedCustom;

  if (isThisUrlFrom(source, "facebook.com")) return FacebookEmbedCustom;

  if (isThisUrlFrom(source, "twitter.com")) return XEmbedCustom;
  if (isThisUrlFrom(source, "x.com")) return XEmbedCustom;

  if (isThisUrlFrom(source, "instagram.com")) return InstagramEmbedCustom;

  if (isThisUrlFrom(source, "datawrapper")) return DataWrapperEmbed;
}
