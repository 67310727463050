import ArticleCard from "@elements/Card/Article/ArticleCard";
import Heading from "@elements/Typography/Heading";
import { isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { MoreLinkProps } from "@typings/StoryPackage.d";
import { twMerge } from "tailwind-merge";

import MoreLink from "./MoreLink";

export type VerticalStoryPackageStyles = {
  wrapperStyle?: string;
  titleStyle?: string;
  moreLinkStyle?: string;
};

export type ArticleCardProps = {
  hasParagraph?: boolean;
  hasSectionName?: boolean;
  hasNewIcon?: boolean;
};

export type TitleIconImgProps = {
  src: string;
  width: number;
  height: number;
};

export type VerticalStoryPackageProps = {
  renderItems: ProcessedArticleData[];
  title?: string;
  styles?: VerticalStoryPackageStyles;
  moreLink?: MoreLinkProps;
  firstArticleProps?: ArticleCardProps;
  restArticleProps?: ArticleCardProps;
  titleIcon?: TitleIconImgProps;
};

export default function VerticalStoryPackage({
  title,
  renderItems,
  styles = {
    wrapperStyle: "",
    titleStyle: "",
    moreLinkStyle: "",
  },
  moreLink,
  firstArticleProps,
  restArticleProps,
  titleIcon,
}: VerticalStoryPackageProps) {
  const { wrapperStyle, titleStyle, moreLinkStyle } = styles;
  const firstArticle = isArrayWithElements(renderItems) && renderItems[0];

  return (
    <div
      className={twMerge(
        "my-sm py-xs border border-red-600 border-x-0 border-b-0 border-t-2",
        wrapperStyle,
      )}
    >
      {title && (
        <div
          className={twMerge(
            "text-sm font-semibold text-grey-350 leading-[24px] mb-xs",
            titleStyle,
          )}
        >
          {titleIcon && (
            <img
              src={titleIcon.src}
              alt="title-icon"
              width={titleIcon.width}
              height={titleIcon.height}
            />
          )}
          <Heading Element="h4" className="font-primary" testId="heading">
            {title}
          </Heading>
        </div>
      )}
      <div className="flex flex-col md:flex-row gap-md">
        <div className="basis-3/6 lg:basis-4/6">
          {firstArticle && (
            <ArticleCard
              key={firstArticle.id}
              testId="article-0"
              forNewDesign={true}
              displayWidth={488}
              displayHeight={366}
              overrideStyle={{
                cardWrapper:
                  "flex flex-col lg:flex-row justify-center w-full gap-md",
                hero: "mb-0",
                headerWrapper: "basis-3/5 block w-full",
                contentWrapper: "basis-2/5 w-full mb-0",
                dateStyle: "text-black-700 font-semimedium leading-[21px]",
                dateTitleWrapper:
                  "flex flex-col-reverse justify-between flex-grow gap-sm",
                titleStyle:
                  "lg:text-[20px] font-medium font-secondary text-black-800 leading-[30px]",
                titleHoverStyle: "hover:text-blue-400",
                sectionNameStyle: "text-[12px] lg:text-xxs font-semibold",
              }}
              elements={{
                hasParaWithTitle: firstArticleProps?.hasParagraph,
                hasSectionName: firstArticleProps?.hasSectionName,
                hasNewIcon: firstArticleProps?.hasNewIcon,
              }}
              {...firstArticle}
            />
          )}
        </div>
        <div className="basis-3/6 lg:basis-2/6 flex flex-col gap-xs">
          {renderItems.slice(1, 4).map((item, idx) => {
            return (
              <ArticleCard
                key={item.id}
                testId={`article-${idx + 1}`}
                forNewDesign={true}
                displayWidth={113}
                displayHeight={86}
                overrideStyle={{
                  cardWrapper: "flex justify-center w-full gap-xs",
                  hero: "mb-xs",
                  headerWrapper:
                    "basis-3/10 md:basis-2/5 lg:basis-3/10 block w-full",
                  contentWrapper: `basis-7/10 md:basis-3/5 lg:basis-7/10 w-full mb-0 ${restArticleProps?.hasSectionName ? "gap-xs" : "gap-0"}`,
                  dateStyle: "text-black-700 font-semimedium leading-[21px]",
                  dateTitleWrapper: "flex flex-col-reverse gap-xs",
                  titleStyle:
                    "lg:text-sm font-medium font-secondary text-black-800 leading-[19.2px]",
                  titleHoverStyle: "hover:text-blue-400",
                  sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
                  sectionNameWrapper: "justify-between",
                }}
                elements={{
                  hasParaWithTitle: restArticleProps?.hasParagraph,
                  hasSectionName: restArticleProps?.hasSectionName,
                  hasNewIcon: restArticleProps?.hasNewIcon,
                }}
                {...item}
              />
            );
          })}
        </div>
      </div>
      {moreLink && (
        <div
          className={twMerge(
            "flex items-center justify-end text-xxs leading-[18px] text-grey-350 font-semibold font-primary border-white-600 border-t-[1px] mt-xs pt-xs",
            moreLinkStyle,
          )}
        >
          <MoreLink
            {...moreLink}
            className={twMerge("flex gap-1", moreLink.className)}
            testId="more-link"
          />
        </div>
      )}
    </div>
  );
}
