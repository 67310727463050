import { AdTargetingType } from "@app/types/Ads.d";
import {
  DEFAULT_GOOGLE_SCRIPT,
  DEFAULT_GOOGLE_SCRIPT_ID,
} from "@components/Elements/Advertisement/AdsConstants";
import useLoadedTags from "@hooks/useLoadedTags/useLoadedTags";
import useScript from "@hooks/useScript/useScript";
import { useCallback, useEffect } from "react";

type GlobalAdSettingProps = {
  targeting?: AdTargetingType;
};

export default function useGlobalAdSetting({
  targeting,
}: GlobalAdSettingProps = {}) {
  const { isScriptReady: isGPTScriptReady } = useScript({
    id: DEFAULT_GOOGLE_SCRIPT_ID,
    src: DEFAULT_GOOGLE_SCRIPT,
    checkScriptIsLoaded: () => {
      return typeof window.googletag !== "undefined" && !!googletag.apiReady;
    },
  });

  const {
    isPrestitialEnabled,
    isCatfishEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  } = useLoadedTags();

  const handleGlobalAdsetting = useCallback(() => {
    googletag.cmd.push(function () {
      if (targeting)
        googletag.pubads().setTargeting(targeting.key, targeting.value);

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
    });
  }, [targeting]);

  useEffect(() => {
    if (typeof window !== "undefined" && isGPTScriptReady) {
      handleGlobalAdsetting();
    }
  }, [handleGlobalAdsetting, isGPTScriptReady]);

  return {
    isPrestitialEnabled,
    isCatfishEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  };
}
