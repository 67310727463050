import ApplyParagraphAnnotations from "@elements/ArticleParagraph/ApplyParagraphAnnotations";
import List, { ListElements } from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { ProcessedTextElement } from "@typings/Opensearch";
import { twMerge } from "tailwind-merge";

type ParagraphListProps = {
  listStyle: string;
  listType: ListElements;
  listItems: ProcessedTextElement[];
};

export default function ParagraphList({
  listStyle,
  listType,
  listItems,
}: ParagraphListProps) {
  return (
    <List
      style={twMerge(
        "font-primaryLight text-black-200 text-2md mb-4 font-extrabold list-inside ps-2md",
        listStyle,
      )}
      Element={listType}
    >
      {listItems.map((item, idx) => (
        <ListItem testId={`item-${idx}`} key={idx}>
          <>{ApplyParagraphAnnotations(item.value, item.annotations)}</>
        </ListItem>
      ))}
    </List>
  );
}
