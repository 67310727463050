import type {
  Point,
  RestaurantData,
  RestaurantDataWithDistance,
} from "@typings/foodMapTypes";

import { getDistance } from "./getDistance";
import { locationFromString } from "./helper";

type UpdateRestaurantDistanceProps = {
  data: RestaurantData[];
  userLocation: Point;
};

export default function UpdateRestaurantDistance({
  data,
  userLocation,
}: UpdateRestaurantDistanceProps): RestaurantDataWithDistance[] {
  const result = data.map((restaurant) => {
    const distance = getDistance({
      source: userLocation,
      destination: locationFromString(restaurant.location),
    });
    return {
      ...restaurant,
      distance: Math.round(distance * 10000) / 10000,
    };
  });

  return result;
}
