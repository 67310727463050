import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useCallback } from "react";

export function useVirtualPVAnalyticsCall() {
  const sendDataToGTM = useGTMDispatch();

  const analyticsCall = useCallback(
    (path: string, title: string, latlong: string) => {
      sendDataToGTM({
        event: "bhVirtualPV",
        page_path: path,
        title: title,
        latlong: latlong,
      });
    },
    [sendDataToGTM],
  );

  return analyticsCall;
}
