import { HeaderMenuList } from "@components/Header/HeaderMenuList";
import { SECONDARY_SECTION_LIST } from "@header/headerMenuConstants";
import type { ReactElement } from "react";

export type SecondaryLinksProps = Readonly<{
  testId?: string;
}>;

export function SecondaryLinks({
  testId = "desktop-secondary-links",
}: SecondaryLinksProps): ReactElement {
  return (
    <div
      data-testid={`${testId}-secondaryLinks`}
      className="hidden lg:w-full lg:flex justiy-between w-8/12 overflow-x-scroll xl:overflow-visible mr-4"
    >
      <div data-testid={`${testId}-secondaryLinksInner`}>
        <HeaderMenuList
          testId={testId}
          items={SECONDARY_SECTION_LIST}
          type="secTopics"
        />
      </div>
    </div>
  );
}
