import type { Point, PositionError } from "@typings/foodMapTypes";
import { useEffect, useState } from "react";

export default function useUserLocation() {
  const [location, setLocation] = useState<Point | null>(null);
  const [error, setError] = useState<
    PositionError | GeolocationPositionError | null
  >(null);

  useEffect(() => {
    const successCallback = (position: GeolocationPosition) => {
      setLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    };

    const errorCallback = (error: GeolocationPositionError) => {
      console.error("Error obtaining location:", error);
      setError(error);
    };
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      alert("Geolocation is not supported by this browser.");
      setError({
        code: 400,
        message: "Geolocation is not supported by this browser.",
      });
    }
  }, []);

  return { location, error };
}
