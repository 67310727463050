import type { ReactElement } from "react";

type NoResultProps = {
  searchedText: string;
};

export default function NoResult({
  searchedText,
}: NoResultProps): ReactElement {
  return (
    <>
      <div className="w-12/12 lg:w-8/12">
        <div className="flex flex-col gap-8">
          <div className="bg-white-200 my-8">
            <div className="p-4 flex items-center flex-col">
              <div className="font-regular italic text-black-200 text-[20px] lg:text-[30px] mb-2">
                {searchedText}
              </div>
            </div>
          </div>
          <p className="text-xxs">Sorry, no results were found.</p>
        </div>
      </div>
    </>
  );
}
