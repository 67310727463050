import hamburgerIcon from "@assets/header/menu/hamburgerIconNew.svg";
import styles from "@components/HeaderNew/Header.module.css";
import cx from "classnames";
import { ReactElement } from "react";

export type MobileMenuIconProps = {
  showMenuIcon: boolean;
  onClick: () => void;
};

export function MobileMenuIcon({
  showMenuIcon,
  onClick,
}: MobileMenuIconProps): ReactElement {
  return (
    <>
      {showMenuIcon ? (
        <button className={cx(styles.hamburgerIcon)} onClick={onClick}>
          <img className={cx(styles.iconMenu)} src={hamburgerIcon} alt="menu" />
        </button>
      ) : null}
    </>
  );
}
