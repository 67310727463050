import { PodcastBHCustomEventCategory } from "@components/Gallery/PodcastGallery";
import HorizontalStoryPackage from "@components/HomePageStoryPackages/HorizontalStoryPackage";
import PodcastPackage from "@components/HomePageStoryPackages/MediaPackages/PodcastPackage";
import VideoPackage from "@components/HomePageStoryPackages/MediaPackages/VideoPackage";
import VerticalStoryPackage from "@components/HomePageStoryPackages/VerticalStoryPackage";
import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import { ProcessedArticleData } from "@hooks/transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import HomeWidgets from "./HomeWidgets";
import WidgetWrapper from "./WidgetWrapper";

export type HomePageNewProps = {
  topStoryQueue: ProcessedArticleData[];
  latestFreeArticle: ProcessedArticleData[];
  adsSectionName: string;
  pageAdTargetValue: PageAdTargetingTypeEnum;
  excludeIds: string;
};
export default function HomePageNew({
  topStoryQueue,
  latestFreeArticle,
  adsSectionName,
  pageAdTargetValue,
  excludeIds,
}: HomePageNewProps) {
  return (
    <>
      <WidgetWrapper>
        <VerticalStoryPackage
          renderItems={topStoryQueue}
          styles={{
            wrapperStyle: "border-t-0",
          }}
          firstArticleProps={{
            hasParagraph: true,
            hasSectionName: true,
            hasNewIcon: true,
          }}
          restArticleProps={{
            hasParagraph: false,
            hasSectionName: true,
            hasNewIcon: true,
          }}
        />
      </WidgetWrapper>
      <div className="w-full bg-white-200">
        <AdsLb2
          uniqueSectionName={adsSectionName}
          pageAdTargetType={pageAdTargetValue}
          hasStripeBg={false}
        />
      </div>
      <WidgetWrapper>
        <HorizontalStoryPackage
          renderItems={latestFreeArticle}
          styles={{
            wrapperStyle: "border-t-0",
            cardInnerWrapperStyle:
              "flex-col md:grid md:grid-cols-2  xl:flex xl:flex-row gap-[20px] w-full",
            cardWrapper:
              "flex flex-row gap-xs xl:gap-0 xl:flex-col xl:items-center justify-center xl:basis-1/4",
            cardHeaderWrapper:
              "basis-3/10 md:basis-2/5 lg:basis-3/10 block w-full",
            cardContentWrapper:
              "basis-7/10 md:basis-3/5 lg:basis-7/10 w-full mb-0 gap-xs text-xxs",
            cardDateTitleWrapper: "flex flex-col-reverse gap-sm",
            sectionNameWrapper: "flex justify-between",
            sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
          }}
        />
      </WidgetWrapper>
      {excludeIds && (
        <HomeWidgets
          excludeIds={excludeIds}
          adsSectionName={adsSectionName}
          pageAdTargetValue={pageAdTargetValue}
        />
      )}
      <WidgetWrapper>
        <PodcastPackage
          bhCustomEventProps={{
            eventCategory: PodcastBHCustomEventCategory.HOME_PAGE,
            eventAction: "click",
          }}
        />
        <VideoPackage />
      </WidgetWrapper>
    </>
  );
}
