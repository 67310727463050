import { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode;
  className?: string;
}>;

/*
 * Container to provide a fixed width and horizontal padding of 15px left and right around the content. Typically paired with a Layout component to create reusable templates across all pages. Width of child content as follows with each screen size:

  Viewport width >= 1200: Children component width: 1170px
  Viewport width >= 992: Children component width: 940px
  Viewport width >= 768: Children component width: 710px
  All other viewport widths: Children component width: 100% - 30px
  
 * @param {ReactElement} children - Any React Element but likely used to wrap a Think China module
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A wrapped module with a fixed width that resizes on view port resize
 */

export function MainContainer({
  children,
  testId = "main-container",
  className,
}: Props): ReactElement {
  return (
    <div
      data-testid={testId}
      className={twMerge(
        "max-w-screen-xl m-auto py-0 lg:px-sm relative w-full",
        className,
      )}
    >
      {children}
    </div>
  );
}
