import "./index.css";
import "./App.module.css";

import { FirebaseNotificationPrompt } from "@components/Elements/FirebaseNotificationPrompt/FirebaseNotificationPrompt";
import { GTM_ID } from "@components/Elements/GAData/GADataConstants";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { RenderContext } from "@sphtech/web2-core/ssr";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

import styles from "./App.module.css";
import HeadScriptProvider from "./providers/HeadScriptProvider";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);
  return (
    <StrictMode>
      <HeadScriptProvider />
      <FirebaseNotificationPrompt />
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <GTMProvider state={{ id: GTM_ID }}>
            <NewRelic />
            <div id="MyPageOverlay" className={styles.overlayWhite}></div>
            <div id="postitial_holder"></div>
            <Outlet />
          </GTMProvider>
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
