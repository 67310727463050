import thumbnailIcon from "@assets/thumbnail/thumbnail.svg";

export type ArticleCarouselFooterProps = {
  caption: string;
  credit: string;
};

export default function ArticleCarouselFooter({
  caption,
  credit,
}: ArticleCarouselFooterProps) {
  return (
    <div className="inline-flex gap-2 items-center bg-blue-100 p-2 w-full">
      <img
        src={thumbnailIcon}
        height={15}
        width={15}
        alt="thumbnail icon"
        className="self-start mt-1"
      />
      <span className="w-full text-white-100 font-primaryLight text-xs font-regular">
        {`${caption} - `} <span className="font-medium">{credit}</span>
      </span>
    </div>
  );
}
