import "@app/styles/error-page.css";

import SPHLogo from "@assets/BeritaHarianLogo.svg";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

type Props = Readonly<{
  message?: string;
}>;

const DEFAULT_MESSAGE = "Server Error!";

/**
 * Error page: this will never render on Server.
 * Added only to handle client side error scenario.
 */
export default function ErrorPage({
  message = DEFAULT_MESSAGE,
}: Props): ReactElement {
  return (
    <>
      <div className="error-page">
        <div id="container">
          <div className="white-container">
            <div className="flex items-center h-auto justify-between py-2 lg:py-4 px-sm lg:px-0">
              <a
                href="/"
                className="w-[157px] h-[30px] lg:w-[330px] lg:h-[63px]"
              >
                <img
                  src={SPHLogo}
                  alt="SPH Logo"
                  className="h-[30px] w-auto lg:h-auto lg:w-full"
                />
              </a>
            </div>
            <p className="font-sans font-24">
              <strong>Dear Reader,</strong>
            </p>
            <p className="font-sans font-18">
              We are experiencing technical difficulties and we are working to
              restore our services as soon as possible.
              <br />
              In the meantime, get the latest news from:
              <br />
              <br />
              <Link
                to="https://www.facebook.com/BeritaHarianSG/"
                target="_blank"
              >
                - BH Facebook{" "}
              </Link>
              <br />
              <Link
                to="https://www.instagram.com/BeritaHarianSG/"
                target="_blank"
              >
                - BH Instagram{" "}
              </Link>
              <br />
              <Link to="https://www.x.com/BeritaHarianSG/" target="_blank">
                - BH Twitter{" "}
              </Link>
              <br />
              <Link
                to="https://www.youtube.com/user/BeritaHarianSG"
                target="_blank"
              >
                - BH YouTube{" "}
              </Link>
              <br />
              <br />
              For urgent assistance, please contact our Customer Experience team
              at <Link to="callto:63883838">6388-3838</Link> or email us at{" "}
              <Link to="mailto:bhnews@sph.com.sg">bhnews@sph.com.sg.</Link>
              <br />
              Our hotline is open from 8.30am to 6pm on Monday to Friday and
              from 8.30am to 12noon on Saturday and Sunday. We are closed on
              public holidays.
              <span className="hidden">{message}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
