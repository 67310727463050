import { stripeAdsBackground } from "@elements/Advertisement/Advertisement";
import { AdsImu2, AdsImu3 } from "@elements/Advertisement/variant/AdsImu";
import { CompanionAdsImu2 } from "@elements/Advertisement/variant/CompanionAdImu";
import LatestNews from "@elements/LatestNews/LatestNews";
import PodcastSideFrame from "@elements/Podcast/PodcastSideFrame";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

export type SideBlockAdsProps = {
  pageAdTargetingProps: PageAdTargetValue;
  uniqueSectionName?: string;
  className?: string;
  adsOnly?: boolean;
  hasImu3?: boolean;
  pageNumber?: number;
  forInfiniteScroll?: boolean;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  index?: number;
};

export default function SideBlockAds({
  uniqueSectionName,
  adsOnly = false,
  hasImu3 = true,
  className,
  pageAdTargetingProps,
  pageNumber = 0,
  forInfiniteScroll = false,
  gsChannels,
  articleSlotTarget,
  index,
}: SideBlockAdsProps) {
  return (
    <div
      className={twMerge(
        "mx-auto flex flex-col items-center h-full min-w-[300px]",
        className,
      )}
      style={stripeAdsBackground}
      key={index}
    >
      {!forInfiniteScroll ? (
        <AdsImu2
          uniqueSectionName={uniqueSectionName}
          pageAdTargetType={pageAdTargetingProps}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
        />
      ) : (
        <CompanionAdsImu2
          uniqueSectionName={uniqueSectionName}
          pageAdTargetType={pageAdTargetingProps}
          index={pageNumber}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
        />
      )}
      {!adsOnly && (
        <>
          <LatestNews />
          <PodcastSideFrame />
        </>
      )}
      {hasImu3 && (
        <AdsImu3
          uniqueSectionName={uniqueSectionName}
          pageAdTargetType={pageAdTargetingProps}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
        />
      )}
    </div>
  );
}
