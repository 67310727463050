import {
  adSlotSizes,
  midArticleAdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

type CompanionAdsImuProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
  index: number;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
};

/**
 * BHBasePage
 * Description: The base page component for all BH pages
 * @name - name of section or page
 */

export function CompanionAdsImu1({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
  gsChannels,
  articleSlotTarget,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(uniqueSectionName),
        id: `dfp-ad-imu1-${index}`,
        adSlotSize: adSlotSizes["imu1"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
        gsChannels,
        articleSlotTarget,
      }}
    />
  );
}
export function CompanionAdsImu2({
  uniqueSectionName,
  pageAdTargetType,
  index,
  gsChannels,
  articleSlotTarget,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <div className="lg:h-[600px]">
      <CompanionAdvertisement
        rootClassName={twMerge(
          "flex justify-center lg:sticky lg:top-[130px]",
          "!py-0",
        )}
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.IMU2,
          slot: GoogleAdsSlotFactory.imu2(uniqueSectionName),
          id: `dfp-ad-imu2-${index}`,
          adSlotSize: adSlotSizes["imu2"],
          pageNumber: index.toString(),
          pubAdTargeting: [PubPageAdTarget],
          gsChannels,
          articleSlotTarget,
        }}
      />
    </div>
  );
}

export function CompanionAdsMidArticle({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
  articleSlotTarget,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(uniqueSectionName),
        id: `dfp-ad-midarticlespecial-${index}`,
        adSlotSize: midArticleAdSlotSizesInfinteScroll["midArticle"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
        articleSlotTarget,
      }}
    />
  );
}
