export const preconnect = "https://tags.crwdcntrl.net";
export const dnsprefetch = "https://bcp.crwdcntrl.net";
export const crwdcntrlScript =
  "https://tags.crwdcntrl.net/lt/c/12374/lt.min.js";

export const lotameScript = `
        !function() {
          // Lotame config
          var lotameClientId = '12374';
          var lotameTagInput = {
            data: {},
            config: {
              clientId: Number(lotameClientId)
            }
          };

          // Lotame initialization
          var lotameConfig = lotameTagInput.config || {};
          var namespace = window['lotame_' + lotameConfig.clientId] = {};
          namespace.config = lotameConfig;
          namespace.data = lotameTagInput.data || {};
          namespace.cmd = namespace.cmd || [];
        } ();
      `;
