import type { Point, PositionError } from "@typings/foodMapTypes";
import { useEffect, useState } from "react";

export default function useWatchPosition() {
  const [userPosition, setUserPosition] = useState<Point | null>(null);
  const [watchError, setWatchError] = useState<
    PositionError | GeolocationPositionError | null
  >(null);

  useEffect(() => {
    let watchId: number;
    const watchOptions = {
      enableHighAccuracy: false,
      timeout: 60000,
      maximumAge: 60000,
    };
    const successCallback = (pos: GeolocationPosition) => {
      const newLocation = {
        lat: parseFloat(pos.coords.latitude.toFixed(7)),
        lng: parseFloat(pos.coords.longitude.toFixed(7)),
      };
      setUserPosition(newLocation);
    };

    const errorCallback = (error: GeolocationPositionError) => {
      console.error("Error obtaining location:", error);
      setWatchError(error);
    };

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        successCallback,
        errorCallback,
        watchOptions,
      );
    } else {
      setWatchError({
        code: 400,
        message: "Geolocation is not supported by this browser.",
      });
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  return { userPosition, watchError };
}
