import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import Heading from "./Heading";

export type ParagraphProps = {
  testid?: string;
  children: ReactNode;
  className?: string;
};

export default function Paragraph({
  testid,
  children,
  className,
}: ParagraphProps) {
  return (
    <p
      data-testid={testid}
      className={twMerge(
        "font-primaryLight text-black-200 text-2md mb-4 font-extrabold",
        className,
      )}
    >
      {children}
    </p>
  );
}

export function StandFirst({ children, className }: ParagraphProps) {
  return (
    <Heading
      className={twMerge(
        "text-grey-400 font-secondary font-semibold leading-xl text-md lg:text-xl",
        className,
      )}
      Element="h6"
    >
      {children}
    </Heading>
  );
}
