import { isClientSide } from "@helper/utils";
import { useEffect } from "react";

const CAMPAIGN_CODE = "bhRegWallCampaign";

export default function RegWallSignupHandler() {
  useEffect(() => {
    if (!isClientSide) return;

    // Function to handle the reg-wall-signup click
    let retryCount = 0;
    const maxRetries = 3;
    const handleSignupClick = (e: Event) => {
      e.preventDefault();

      const userObj = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        phone: "",
      };

      if (typeof window._mySPHObj === "undefined") {
        console.log("_mySPHObj not defined. Retrying...");
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(() => {
            handleSignupClick(e);
          }, 100);
        } else {
          console.log(
            `_mySPHObj still not defined after ${maxRetries} retries.`,
          );
        }
      } else {
        /* eslint-disable @typescript-eslint/no-unsafe-call */
        window._mySPHObj.openSignUp(userObj, CAMPAIGN_CODE);
      }
    };

    // Function to handle document click
    const handleDocumentClick = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains("web2-reg-wall-signup")) {
        handleSignupClick(e);
      }
    };

    // Add event listener once
    document.addEventListener("click", handleDocumentClick);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return null;
}
