import { twMerge } from "tailwind-merge";
export default function Divider({ className }: { className?: string }) {
  return (
    <div
      className={twMerge(
        "w-full h-xxs bg-blue-300 rounded-thumbnail",
        className,
      )}
    ></div>
  );
}

export const DividerDot = () => {
  return (
    <div className="flex items-center gap-x-6">
      <Divider className="w-[49%]" />
      <Divider className="w-[5px] rounded-full" />
      <Divider className="w-[49%]" />
    </div>
  );
};
