import beritaTerkini from "@assets/berita-terkini.svg";
export default function LatestNewsHeader() {
  return (
    <div className="flex items-center justify-start bg-white-100 max-w-max py-2">
      <img
        src={beritaTerkini}
        alt="berita-terikini"
        data-testid="beritaTerikiLogo"
        width={20}
        height={20}
      />
      <span className="text-md text-red-200 ml-2">Berita Terkini</span>
    </div>
  );
}
