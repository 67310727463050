import { RestaurantDataWithDistance } from "@app/types/foodMapTypes";

import FoodMapRestaurantCard from "./FoodMapRestaurantCard";

type FoodMapRestaurantListProps = {
  data: RestaurantDataWithDistance[];
  filterDistance: number;
  onSelectingRestaurant: (restaurant: RestaurantDataWithDistance) => void;
};

export default function FoodMapRestaurantList({
  data,
  filterDistance = 1,
  onSelectingRestaurant,
}: FoodMapRestaurantListProps) {
  const closeRestaurants = data.filter(
    (restaurant) => restaurant.distance <= filterDistance,
  );
  const distantRestaurants = data.filter(
    (restaurant) => restaurant.distance > filterDistance,
  );
  let index = 0;

  return (
    <>
      {closeRestaurants.length == 0 && filterDistance < 1 && (
        <div className="text-[17px] font-regular text-center text-black-300 pt-xl pb-xxs">
          No restaurant found within {filterDistance * 1000} Meters from your
          distance.
        </div>
      )}
      {closeRestaurants.map((restaurant) => (
        <FoodMapRestaurantCard
          key={index}
          index={index++}
          restaurant={restaurant}
          handleRestaurantSelect={onSelectingRestaurant}
        />
      ))}
      {distantRestaurants.length > 0 && (
        <div className="text-sm text-center py-[8px] text-black-300">
          <b>Try restaurants slightly further away:</b>
        </div>
      )}
      {distantRestaurants.map((restaurant) => (
        <FoodMapRestaurantCard
          key={index}
          index={index++}
          restaurant={restaurant}
          handleRestaurantSelect={onSelectingRestaurant}
        />
      ))}
    </>
  );
}
