import {
  FB_API_KEY,
  FB_APP_ID,
  FB_AUTH_DOMAIN,
  FB_DATABASE_URL,
  FB_MEASURMENT_ID,
  FB_MESSAGING_SENDER_ID,
  FB_PROJECT_ID,
  FB_SERVER_KEY,
  FB_STORAGE_BUCKET,
  FB_TOKEN_TOPIC,
  FB_VAPID_KEY,
  REACT_APP_URL,
} from "@helper/getEnvVariables";
import { getErrorMessage, reportError } from "@helper/getErrorMessage";
import { removeTrailingSlash } from "@helper/removeTrailingLeadingSlash";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

import { SUBSCRIBE_API_URL } from "./FirebaseConstants";

const firebaseConfig = {
  apiKey: FB_API_KEY,
  authDomain: FB_AUTH_DOMAIN,
  databaseURL: FB_DATABASE_URL,
  projectId: FB_PROJECT_ID,
  storageBucket: FB_STORAGE_BUCKET,
  messagingSenderId: FB_MESSAGING_SENDER_ID,
  appId: FB_APP_ID,
  measurementId: FB_MEASURMENT_ID,
};

const UrlFirebaseConfig = new URLSearchParams({
  apiKey: FB_API_KEY,
  authDomain: FB_AUTH_DOMAIN,
  projectId: FB_PROJECT_ID,
  storageBucket: FB_STORAGE_BUCKET,
  messagingSenderId: FB_MESSAGING_SENDER_ID,
  appId: FB_APP_ID,
});

const paramsString = UrlFirebaseConfig.toString();
const swUrl = `${removeTrailingSlash(REACT_APP_URL)}/firebase-messaging-sw.js?${paramsString}`;

export const firebaseApp = initializeApp(firebaseConfig);

// Initialize messaging asynchronously and export a promise
export const messagingPromise = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    reportError({
      message: `
      An error occurred while initializing Firebase messaging:
      ${getErrorMessage(err)}`,
    });
    return null;
  }
})();

const waitForServiceWorkerActivation = (
  registration: ServiceWorkerRegistration,
): Promise<ServiceWorkerRegistration> => {
  return new Promise((resolve, reject) => {
    if (registration.active) {
      resolve(registration);
      return;
    }
    const serviceWorker = registration.installing || registration.waiting;
    if (serviceWorker !== null) {
      serviceWorker.addEventListener("statechange", (event) => {
        const target = event.target as ServiceWorker;
        if (target.state === "activated") {
          resolve(registration);
        }
      });
    } else {
      reject(new Error("No installing or waiting service worker found."));
    }
  });
};

export const getOrRegisterServiceWorker = async () => {
  if (
    "serviceWorker" in navigator &&
    typeof navigator.serviceWorker !== "undefined"
  ) {
    try {
      const serviceWorker = await navigator.serviceWorker.getRegistration(
        "/firebase-cloud-messaging-push-scope",
      );
      if (serviceWorker)
        return await waitForServiceWorkerActivation(serviceWorker);
      const newServiceWorder = await navigator.serviceWorker.register(swUrl, {
        scope: "/firebase-cloud-messaging-push-scope",
      });
      return await waitForServiceWorkerActivation(newServiceWorder);
    } catch (error) {
      reportError({
        message: `Service worker registration failed: ${getErrorMessage(error)}`,
      });
    }
  } else {
    console.log("The browser doesn't support service worker.");
  }
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messagingPromise;
    if (messagingResolve) {
      const serviceWorkerRegistration = await getOrRegisterServiceWorker();
      return await getToken(messagingResolve, {
        vapidKey: FB_VAPID_KEY,
        serviceWorkerRegistration,
      });
    } else {
      reportError({ message: "Firebase messaging is not initialized." });
      return null;
    }
  } catch (error) {
    reportError({
      message: `An error occurred while retrieving Firebase token: ${getErrorMessage(error)}`,
    });
    return null;
  }
};

export const subscribeTokenToTopic = async () => {
  try {
    const token = await getFirebaseToken();
    if (!token) {
      reportError({ message: "Failed to retrieve Firebase token." });
      return;
    }
    const response = await fetch(
      `${SUBSCRIBE_API_URL}${token}/rel/topics/${FB_TOKEN_TOPIC}`,
      {
        method: "POST",
        headers: {
          Authorization: `key=${FB_SERVER_KEY}`,
        },
      },
    );

    if (!response.ok) {
      reportError({
        message: `Error subscribing to topic: ${response.status} - ${await response.text()}`,
      });
      return;
    }

    console.log("Subscribed Firebase.");
    localStorage.setItem("isFirebasePushEnabled", "1");
  } catch (error) {
    reportError({
      message: `Error subscribing to topic: ${getErrorMessage(error)}`,
    });
  }
};
