import WrapperContainer from "@elements/ArticleParagraph/ParagraphElements/WrapperContainer";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { TikTokEmbed, TikTokEmbedProps } from "react-social-media-embed";

import { SOCIAL_EMBED_WIDTH } from "./constants";

type TiktokEmbedCustomProps = {
  url: TikTokEmbedProps["url"];
  className?: string;
};

export default function TiktokEmbedCustom({
  url,
  className = "",
}: TiktokEmbedCustomProps): React.ReactElement {
  const { isScreenMD } = useWindowSize();

  return (
    <WrapperContainer className={className}>
      <TikTokEmbed url={url} width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"} />
    </WrapperContainer>
  );
}
