import { NeuronProvider as Provider, SPAConfig } from "@sphtech/neuron";
import { ReactNode } from "react";

import TrackerProvider from "./TrackerProvider";

type Props = {
  children: ReactNode;
};

export default function NeuronProvider({ children }: Props): ReactNode {
  // Disable Neuron in CUE preview
  if (
    typeof window !== "undefined" &&
    window.location.search.includes("preview_token=")
  ) {
    return children;
  }

  const neuronConfig: SPAConfig = {
    trackedSPAEvents: [
      {
        eventType: "click",
        selector: "button, a",
      },
      {
        eventType: "elementVisible",
        selector: "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
      },
    ],
    sessionApiUrl: import.meta.env.VITE_NEURON_API_URL as string,
    sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN as string,
  };

  return (
    <Provider config={neuronConfig}>
      <TrackerProvider>{children}</TrackerProvider>
    </Provider>
  );
}
