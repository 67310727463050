export enum FeatureFlags {
  GEN_G = "FEATURE_GEN_G",
  WEBINAR = "FEATURE_WEBINAR",
  NEW_MASTHEAD = "FEATURE_NEW_MASTHEAD",
  NEW_HOMEPAGE = "FEATURE_NEW_HOMEPAGE",
}

export enum ClientSideSecrets {
  BC_ACCOUNT_ID = "BC_ACCOUNT_ID",
}
