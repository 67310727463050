import moreLinkIcon from "@assets/images/more-link-icon.svg";
import Link from "@elements/Typography/Link";
import { MoreLinkProps } from "@typings/StoryPackage.d";

export default function MoreLink({
  destination,
  linkText = "Laporan lain",
  className = "",
  relativePath = true,
  target = "_self",
  testId = "more-link",
}: MoreLinkProps) {
  return (
    <Link
      destination={destination}
      className={className}
      relativePath={relativePath}
      target={target}
      testId={testId}
    >
      <span className="text-xxs">{linkText}</span>
      <img src={moreLinkIcon} width={16} height={16} alt="more link icon" />
    </Link>
  );
}
