import registeredUser from "@assets/header/menu-selected/icon/registered-user-new.svg";
import CASBlock from "@components/CASBlock/CASBlock";
import HorizontalStoryPackage, {
  HorizontalStoryPackageProps,
} from "@components/HomePageStoryPackages/HorizontalStoryPackage";
import VerticalStoryPackage, {
  VerticalStoryPackageProps,
} from "@components/HomePageStoryPackages/VerticalStoryPackage";
import { AdsImu1 } from "@elements/Advertisement/variant/AdsImu";
import { AdsLb2 } from "@elements/Advertisement/variant/AdsLb";
import fetchBHApi from "@helper/fetchBHApi";
import { CAS_CDP_HOME_ID } from "@helper/getEnvVariables";
import { findStyleBySectionNew, isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import {
  FilterArticlesBasedOn,
  HomeWidgetType,
  StoryPackageConfig,
} from "@typings/StoryPackage.d";
import { useCallback, useEffect, useMemo, useState } from "react";

import { homeWidgetLayout } from "./layout";
import WidgetWrapper from "./WidgetWrapper";

export type WidgteMappings = {
  widgetProps: HorizontalStoryPackageProps | VerticalStoryPackageProps | null;
  widgetType: StoryPackageConfig;
};

export type HomeWidgetsProps = {
  excludeIds: string;
  adsSectionName: string;
  pageAdTargetValue: PageAdTargetingTypeEnum;
};

export default function HomeWidgets({
  excludeIds,
  adsSectionName,
  pageAdTargetValue,
}: HomeWidgetsProps) {
  const [widgetsData, setWidgetsData] = useState<WidgteMappings[]>([]);
  const [fetchedWidgetIndices, setFetchedWidgetIndices] = useState<Set<number>>(
    new Set(),
  );

  const prepareWidgetData = useCallback(async () => {
    const newWidgetsData: WidgteMappings[] = [...widgetsData];
    const newFetchedIndices = new Set(fetchedWidgetIndices);
    let accumulatedIds = excludeIds;

    for (const [index, widget] of homeWidgetLayout.entries()) {
      if (fetchedWidgetIndices.has(index)) continue;

      try {
        const endpoint =
          widget.filterArticlesBasedOn === FilterArticlesBasedOn.SECTION
            ? `section/${widget.filterText}`
            : widget.filterArticlesBasedOn === FilterArticlesBasedOn.TAGS
              ? `tags/${widget.filterText}`
              : `paywall/${widget.filterText}`;

        const size = widget.type === HomeWidgetType.HORIZONTAL ? 10 : 4;
        const data = await fetchBHApi<ProcessedArticleData[]>(
          `${endpoint}?size=${size}&charCount=130&excludeIds=${accumulatedIds}`,
          "GET",
        );

        if (isArrayWithElements(data)) {
          const fetchedIds = data.map((article) => article.id);
          accumulatedIds = `${accumulatedIds},${fetchedIds.join(",")}`;

          let titleColor = "text-purple-300";
          let borderColor = "border-purple-300";
          if (widget.filterArticlesBasedOn === FilterArticlesBasedOn.SECTION) {
            const { selectedColor } =
              findStyleBySectionNew(widget.filterText) || {};
            titleColor = selectedColor ? `text-${selectedColor}` : "";
            borderColor = selectedColor ? `border-${selectedColor}` : "";
          } else if (
            widget.filterArticlesBasedOn === FilterArticlesBasedOn.TAGS
          ) {
            titleColor = "text-blue-400";
            borderColor = "border-blue-400";
            if (widget.filterText === "dna-dunia-anak") {
              titleColor = "text-blue-350";
              borderColor = "border-blue-350";
            }
          } else if (
            widget.filterArticlesBasedOn === FilterArticlesBasedOn.PAYWALL
          ) {
            titleColor = "flex gap-xs items-center text-purple-300 pl-xxs";
          }

          const widgetProps:
            | HorizontalStoryPackageProps
            | VerticalStoryPackageProps = {
            renderItems: data,
            title: widget.title,
            moreLink: widget.moreLink,
            styles: {
              wrapperStyle: borderColor,
              titleStyle: titleColor,
              moreLinkStyle: "",
              cardWrapperStyle: "",
              ...(widget.type === HomeWidgetType.HORIZONTAL && {
                sectionNameStyle: "text-[12px] lg:text-[12px] font-semibold",
              }),
            },
            showSection:
              widget.filterArticlesBasedOn !== FilterArticlesBasedOn.SECTION,
            showContentAccessLabel:
              widget.filterArticlesBasedOn !== FilterArticlesBasedOn.PAYWALL,
            ...(widget.title === "Pengguna Berdaftar" && {
              titleIcon: {
                src: registeredUser,
                width: 16,
                height: 16,
              },
            }),
          };

          newWidgetsData[index] = { widgetProps, widgetType: widget };
          newFetchedIndices.add(index);
        }
      } catch (error) {
        newFetchedIndices.add(index);
        console.error(
          `Failed to fetch data for widget at index ${index}`,
          error,
        );
        newWidgetsData[index] = { widgetProps: null, widgetType: widget };
      }
    }

    setFetchedWidgetIndices(newFetchedIndices);
    setWidgetsData(newWidgetsData);
  }, [fetchedWidgetIndices, widgetsData, excludeIds]);

  useEffect(() => {
    if (fetchedWidgetIndices.size === 0) {
      void prepareWidgetData();
    }
  }, [prepareWidgetData, fetchedWidgetIndices.size]);

  const memoizedWidgets = useMemo(
    () =>
      widgetsData.map((widget, index) => (
        <div
          data-testid={widget.widgetType.title.replace(" ", "-")}
          key={index}
        >
          {widget.widgetProps ? (
            widget.widgetType.type === HomeWidgetType.VERTICAL ? (
              <WidgetWrapper>
                <VerticalStoryPackage {...widget.widgetProps} />
              </WidgetWrapper>
            ) : (
              <WidgetWrapper>
                {widget.widgetType.title === "Kaki Makan" ? (
                  <div className="flex flex-col lg:flex-row gap-xs">
                    <div className="lg:max-w-[645px] xl:max-w-[860px]">
                      <HorizontalStoryPackage {...widget.widgetProps} />
                    </div>
                    <div className="flex w-full justify-center items-center">
                      <AdsImu1
                        uniqueSectionName={adsSectionName}
                        pageAdTargetType={pageAdTargetValue}
                      />
                    </div>
                  </div>
                ) : (
                  <HorizontalStoryPackage {...widget.widgetProps} />
                )}
              </WidgetWrapper>
            )
          ) : (
            <div data-testid="widget-placeholder">
              <p>
                Unable to load content for{" "}
                {widget.widgetType.title || "this widget"}.
              </p>
            </div>
          )}
          {index === 0 && (
            <div className="w-full bg-white-200">
              <AdsLb2
                uniqueSectionName={adsSectionName}
                pageAdTargetType={pageAdTargetValue}
                hasStripeBg={false}
                index={1}
              />
            </div>
          )}
          {index === 2 && (
            <WidgetWrapper>
              <CASBlock casId={CAS_CDP_HOME_ID} />
            </WidgetWrapper>
          )}
        </div>
      )),
    [widgetsData, adsSectionName, pageAdTargetValue],
  );

  return <div data-testid="home-widgets">{memoizedWidgets}</div>;
}
