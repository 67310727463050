import { isArrayWithElements, isClientSide } from "@helper/utils";
import { useBHCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useKuizVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useKuizVirtualPVAnalyticsCall";
import { capitalizeFirstCharOnly } from "@src/app/helper/stringTransform";
import { useCallback, useEffect, useState } from "react";

import { shuffle } from "./helpers/shuffle";
import QuestionDetailSection, { Questions } from "./QuestionDetailSection";
import ResultsSection from "./ResultsSection";
import { fetchPuzzleData } from "./server/fetchPuzzleData";
import StatusMessage from "./StatusMessage";

type HandleQuizNavigation = () => void;

export type WordPuzzleProps = {
  DefaultQuestions?: Questions;
}; // Props with DefaultQuestions from storiesbook

export default function WordPuzzlePage(props: WordPuzzleProps): JSX.Element {
  const { DefaultQuestions } = props;
  const currentDate = new Date();
  const puzzleDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
  const [options, setOptions] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [selectedWord, setSelectedWord] = useState<string | null>(null);
  const [englishWord, setEnglishWord] = useState<string>(""); // The question
  const [correctMalayWord, setCorrectMalayWord] = useState<string>("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const [incorrectCombination, setIncorrectCombination] = useState<string>("");
  const [questionNum, setQuestionNum] = useState<number>(1);
  const [showNextPre, setShowNextPrev] = useState<boolean>(false);
  const [puzzles, setPuzzles] = useState<Questions>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [hasAnswered, setHasAnswered] = useState<boolean>(false); // Added state to track if the question has been answered

  const bhCustomEventAnalyticsCall = useBHCustomEventAnalyticsCall();
  const virtualPVAnalyticsCall = useKuizVirtualPVAnalyticsCall();

  function handleWindowSizeChange() {
    if (isClientSide) {
      setIsMobile(window.innerWidth <= 768);
    }
  }

  useEffect(() => {
    if (!isClientSide) return;
    setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const pushKuizBHCustomEvent = useCallback(
    (
      eventAction: "visible" | "success" | "failed" | "click",
      eventLabel: string,
      pagination?: string,
    ) => {
      bhCustomEventAnalyticsCall(
        "word quiz",
        eventAction,
        eventLabel,
        undefined,
        pagination,
      );
    },
    [bhCustomEventAnalyticsCall],
  );

  useEffect(() => {
    async function load_initial_puzzle() {
      const data = (await fetchPuzzleData(puzzleDate)) as Questions;
      if (isArrayWithElements(DefaultQuestions) || data.length) {
        loadQuestion(
          isArrayWithElements(DefaultQuestions) ? DefaultQuestions[0] : data[0],
        );
        if (data.length) {
          pushKuizBHCustomEvent("visible", "Kuiz BH", "1");
          virtualPVAnalyticsCall("Kuiz BH", "1");
        }
      }
      setPuzzles(data);
    }
    void load_initial_puzzle();
  }, [
    puzzleDate,
    DefaultQuestions,
    pushKuizBHCustomEvent,
    virtualPVAnalyticsCall,
  ]);

  const loadQuestion = (question: { [key: string]: string }) => {
    const malayWords = Object.keys(question);
    const questionWord = malayWords[0];
    setEnglishWord(questionWord);
    const correctAnswer = question[questionWord];
    setCorrectMalayWord(correctAnswer.toLowerCase());
    setOptions(shuffle([...malayWords.slice(1), correctAnswer]));
    setHasAnswered(false); // Reset hasAnswered when loading a new question
    setSelectedWord(null);
  };

  const handleWordClick = useCallback(
    (word: string) => {
      setSelectedWord(word);

      if (word.toLowerCase() === correctMalayWord.toLowerCase()) {
        if (!resultsVisible || showNextPre) {
          pushKuizBHCustomEvent("success", "Betul!", `${questionNum}`);
          setStatus("correct");
          setIncorrectCombination(""); // Clear incorrect combination on correct answer
        }
        setTimeout(() => {
          if (!resultsVisible || showNextPre) setStatus("correctNext");
          const nextIndex = currentQuestionIndex + 1;
          if (nextIndex < puzzles.length) {
            setQuestionNum(questionNum + 1);
            setCurrentQuestionIndex(nextIndex);
            loadQuestion(puzzles[nextIndex]);
            pushKuizBHCustomEvent("visible", "Kuiz BH", `${nextIndex + 1}`);
            virtualPVAnalyticsCall("Kuiz BH", `${nextIndex + 1}`);
            setSelectedWord(null);
          } else {
            setShowNextPrev(true);
            setResultsVisible(true);
            pushKuizBHCustomEvent("visible", "Kuiz BH Complete");
            virtualPVAnalyticsCall("Kuiz BH Complete", `${nextIndex}`);
            setStatus("");
          }
        }, 1000);
      } else {
        pushKuizBHCustomEvent(
          "failed",
          `Salah! ${capitalizeFirstCharOnly(word)} = ${capitalizeFirstCharOnly(puzzles[currentQuestionIndex][word])}`,
          `${currentQuestionIndex + 1}`,
        );
        setStatus("incorrect");
        setIncorrectCombination(
          `${capitalizeFirstCharOnly(word)} = ${capitalizeFirstCharOnly(puzzles[currentQuestionIndex][word])}`,
        );
        if (!hasAnswered) {
          // Check if the question has already been answered incorrectly
          setHasAnswered(true); // Mark the question as answered
        }
        setTimeout(() => {}, 1000);
      }
    },
    [
      puzzles,
      correctMalayWord,
      currentQuestionIndex,
      hasAnswered,
      questionNum,
      resultsVisible,
      showNextPre,
      pushKuizBHCustomEvent,
      virtualPVAnalyticsCall,
    ],
  );

  const handleQuizNavigation: HandleQuizNavigation = () => {
    pushKuizBHCustomEvent("click", "Cuba kuiz baru");
    setOptions([]);
    setStatus("");
    setCorrectMalayWord("");
    setQuestionNum(1);
    setCurrentQuestionIndex(0);
    setResultsVisible(false);
    fetchPuzzleData(puzzleDate)
      .then((data) => {
        setPuzzles(data as Questions);
        if (data) {
          loadQuestion(data[0]);
          pushKuizBHCustomEvent("visible", "Kuiz BH", "1");
          virtualPVAnalyticsCall("Kuiz BH", "1");
        }
      })
      .catch(() => {
        setResultsVisible(true);
      });
  };

  return (
    <div id="word-puzzle-page" className="bottom-0 z-50 w-full left-0">
      <div>
        <QuestionDetailSection
          isMobile={isMobile}
          questionNum={questionNum}
          questions={
            isArrayWithElements(DefaultQuestions) && DefaultQuestions.length > 0
              ? DefaultQuestions
              : puzzles
          }
          englishWord={englishWord}
          options={options}
          selectedWord={selectedWord}
          correctMalayWord={correctMalayWord}
          handleWordClick={handleWordClick}
          resultVisible={resultsVisible}
        />
        {!status && !resultsVisible && (
          <div className="flex items-center justify-center flex-row  w-full h-[48px] md:h-14 bg-white-100">
            <span className="text-sm text-green-500 font-primary">
              Pilih perkataan yang betul
            </span>
          </div>
        )}
        {!resultsVisible && (
          <StatusMessage
            status={status}
            incorrectCombination={incorrectCombination}
          />
        )}
        {resultsVisible && (
          <ResultsSection handleQuizNavigation={handleQuizNavigation} />
        )}
      </div>
    </div>
  );
}
