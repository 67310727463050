import styles from "@header/Search/Search.module.css";
import {
  isSameOrigin,
  prefixCheck,
  sanitizeSearchKeyword,
} from "@helper/utils";
import cx from "classnames";
import { ChangeEvent, KeyboardEvent, useState } from "react";

type SearchInputProps = {
  expanded: boolean;
  isMobile?: boolean;
};

export function SearchInput({ expanded, isMobile }: SearchInputProps) {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const sanitized = sanitizeSearchKeyword(event.target.value);
    setSearchValue(sanitized);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!searchValue.trim()) return;
      if (prefixCheck(searchValue)) return;
      if (!isSameOrigin(window.origin)) return;
      window.location.href = `/search/site/${encodeURIComponent(searchValue)}`;
    }
  };

  return (
    <>
      {expanded ? (
        <div
          data-testid="search"
          className={cx(styles.searchInner, { [styles.isMobile]: isMobile })}
        >
          <div
            data-testid="searchExpanded"
            className={cx(styles.searchExpanded)}
          >
            <input
              type="search"
              placeholder="Kata Carian..."
              className={cx(styles.searchInput)}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleSearch}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
