import iconUrl from "@assets/push-icon/apple-touch-icon-114x114.png";
import { getErrorMessage, reportError } from "@helper/getErrorMessage";
import { useEffect, useState } from "react";

import { subscribeTokenToTopic } from "./FirebaseNotificationConfig";

interface NotificationData {
  value: number;
  timeStamp: number;
}

const promptDismissedData: NotificationData = {
  value: 1,
  timeStamp: Date.now() + 86400000, // 1 day
};

export function FirebaseNotificationPrompt() {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const checkPrompt = () => {
      const isFireBaseEnabled = localStorage.getItem(
        "isFirebasePushEnabled",
      ) as string;
      /* eslint-disable @typescript-eslint/no-unnecessary-condition */
      if (isFireBaseEnabled == null) {
        const notificationPrompt = localStorage.getItem(
          "promptDismissed",
        ) as string;
        if (notificationPrompt) {
          const { value, timeStamp } = JSON.parse(
            notificationPrompt,
          ) as NotificationData;
          if (
            value === 1 &&
            timeStamp <= Date.now() &&
            Notification.permission !== "denied"
          ) {
            setShowPrompt(true);
          }
        } else if (Notification.permission !== "denied") {
          setShowPrompt(true);
        }
      }
    };

    const timeout = setTimeout(checkPrompt, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handlePermission = async () => {
    setShowPrompt(false);
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        await subscribeTokenToTopic();
      }
    } catch (error) {
      reportError({
        message: `Error requesting notification permission: ${getErrorMessage(error)}`,
      });
    }
  };

  const handleOkButtonClick = () => {
    handlePermission().catch((error) => {
      reportError({
        message: `Something went wrong: ${getErrorMessage(error)}`,
      });
      return;
    });
  };

  const handleDismiss = () => {
    localStorage.setItem(
      "promptDismissed",
      JSON.stringify(promptDismissedData),
    );
    setShowPrompt(false);
  };

  return (
    showPrompt && (
      <div className="w-full fixed z-[99] left-0 right-0 bottom-0 sm:bottom-auto">
        <div className="md:w-[500px] mx-auto bg-white-100 rounded-md shadow-lg">
          <div className="p-[24px]">
            <div className="flex">
              <div className="w-[80px] h-[80px] flex-shrink-0">
                <img
                  alt="notification icon"
                  className="w-full h-full"
                  src={iconUrl}
                />
              </div>
              <div className="pl-md text-sm text-black-350">
                Kami ingin kongsi berita terkini dengan anda.
              </div>
            </div>
            <div className="flex flex-row-reverse mt-4">
              <button
                className="px-[24px] py-[12px] text-sm text-white-100 bg-blue-750 rounded"
                onClick={handleOkButtonClick}
              >
                Ok, boleh
              </button>
              <button
                className="px-[24px] py-[12px] mr-xs text-sm text-blue-750 border rounded"
                onClick={handleDismiss}
              >
                Tidak perlu
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
