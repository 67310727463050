import { useLocation } from "react-router-dom";

export const useSubscribeLink = () => {
  const location = useLocation();
  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get("mobwebview")
    ? ""
    : "https://subscribe.sph.com.sg/publications-bh/?utm_campaign=bh_subscription&utm_medium=sph-publication&utm_source=bh&utm_content=subscribelink-header";
};
